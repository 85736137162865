import { useState } from 'react';
import { Col, Container, Row, Button, Form, Modal } from 'react-bootstrap';
import ReactSlider from "react-slider";
import stringUtilities from '../../../utilities/stringUtilities';
import Textbox from '../../form/textbox';
import SimpleBar from "simplebar-react";

export const WorkflowAddEdit = ({ title, name, setName, isComposite, setIsComposite, workflowTemplates, workflowTemplateId, handleWorkflowTemplateChange, workflowType, setWorkflowType, hue, setHue, rgbColor, setRgbColor, order, setOrder, configuration, handleConfigurationChange, canSave, handleSubmit, handleCancel, handleSaveTemplate, canSaveTemplate, isValidJson }) => {

    const [newTemplateName, setNewTemplateName] = useState("");
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    return (
        <Container className='pt-5 pb-lg-4 mt-5 mb-sm-2'>
            <Row>
                <Col className='mb-5'>
                    <div className='d-flex justify-content-between my-5'>
                        <h1 className="h2 m-0">{ title }</h1>
                    </div>
                    <div>
                        <Form.Group className='mb-3 row'>
                            <Form.Label className='form-label fs-sm col-form-label col-md-3' htmlFor="workflow-name">
                                Name
                            </Form.Label>
                            <div className="col-md-9 d-flex">
                                <Form.Control size='sm' value={ name } type="text" onChange={ (e) => setName(e.target.value) } />
                            </div>
                        </Form.Group>

                        <Form.Group className='mb-3 row'>
                            <Form.Label className='form-label fs-sm col-form-label col-md-3' htmlFor="workflow-composite">
                                Composite
                            </Form.Label>
                            <div className="col-md-9 d-flex align-items-center">
                                <Form.Check type='switch' id="workflow-composite" checked={ isComposite } onChange={ (e) => setIsComposite(e.target.checked) } />
                            </div>
                        </Form.Group>

                        <Form.Group className='mb-3 row'>
                            <Form.Label className='form-label fs-sm col-form-label col-md-3' htmlFor="workflow-template">
                                Workflow Template
                            </Form.Label>
                            <div className="col-md-9 d-flex align-items-center">
                                <Form.Select size='sm' value={ workflowTemplateId } onChange={ (e) => {
                                    handleWorkflowTemplateChange(e.target.value)
                                } }>
                                    <option value="">Select a Template</option>
                                    {
                                        workflowTemplates
                                        .map(item => <option key={item.id} value={item.id}>{item.name}</option>)
                                    }
                                </Form.Select>
                            </div>
                        </Form.Group>

                        <Form.Group className='mb-3 row'>
                            <Form.Label className='form-label fs-sm col-form-label col-md-3' htmlFor="workflow-type">
                                Workflow Type
                            </Form.Label>
                            <div className="col-md-9 d-flex align-items-center">
                                <Form.Select disabled={workflowTemplateId} size='sm' value={ workflowType } onChange={ (e) => setWorkflowType(e.target.value) }>
                                    <option value="9">Non-Sensor</option>
                                    <option value="10">Sensor</option>
                                </Form.Select>
                            </div>
                        </Form.Group>

                        <Form.Group className='mb-3 row'>
                            <Form.Label className='form-label fs-sm col-form-label col-md-3' htmlFor="workflow-hue">
                                Hue
                            </Form.Label>
                            <div className="col-md-9 d-flex align-items-center">
                                <ReactSlider disabled={workflowTemplateId} className='range-slider range-slider-single' thumbClassName='range-slider-handle' trackClassName='range-slider-track' min={0} max={360} value={hue} ariaLabel={['Handle']} ariaValuetext={state => `Handle value ${state.valueNow}`} step={1} onChange={(e) => setHue(e)} renderThumb={(props, state) => (
                                    <div {...props}>
                                        <div className='range-slider-tooltip'>{state.valueNow}</div>
                                    </div>
                                )}
                                />
                                <div style={{backgroundColor: `hsl(${hue}, 100%, 50%)`, width: "200px", height: "40px", marginLeft: "1rem", borderRadius: "0.30rem"}} />
                            </div>
                        </Form.Group>

                        <Form.Group className='mb-3 row'>
                            <Form.Label className='form-label fs-sm col-form-label col-md-3' htmlFor="workflow-rgbcolor">
                                RGB Color
                            </Form.Label>
                            <div className="col-md-9 d-flex align-items-center">
                                <div className='color-picker'>
                                    <Form.Control id="workflow-color-picker" disabled={ workflowTemplateId } value={ rgbColor ?? "#000000" } type="color" onChange={ (e) => setRgbColor(e.target.value) } className={`color-display ${rgbColor ? "visible" : "invisible"}`} />
                                    <div onClick={ () => {
                                        const picker = document.querySelector("#workflow-color-picker");
                                        picker.showPicker()
                                    } } className={`color-display null-display ${rgbColor ? "invisible" : "visible"}`}>
                                    </div>
                                </div>
                                <div className='d-flex gap-2 align-items-center'>
                                    <p className="m-0">{ rgbColor ?? "None" }</p>
                                    { (rgbColor && !workflowTemplateId) && <button className='btn btn-link fi-x m-0 p-1' disabled={workflowTemplateId} onClick={() => setRgbColor(null)}></button> }
                                </div>
                            </div>
                        </Form.Group>

                        <Form.Group className='mb-3 row'>
                            <Form.Label className='form-label col-form-label fs-sm col-md-3' htmlFor="workflow-order">
                                Order
                            </Form.Label>
                            <div className="col-md-9 d-flex align-items-center">
                                <Form.Control size='sm' value={ order } type="text" onChange={ (e) => setOrder(e.target.value) } />
                            </div>
                        </Form.Group>

                        <Form.Group className='mb-3 row'>
                            <Form.Label className='form-label fs-sm col-form-label col-md-3' htmlFor="workflow-configuration">
                                Configuration
                            </Form.Label>
                            <div className="col-md-9 d-flex flex-column">
                                <Form.Control disabled={workflowTemplateId} size='sm' value={ configuration } as="textarea" rows={ 10 } onChange={ (e) => handleConfigurationChange(e.target.value) } />
                                {!isValidJson && <small className='text-danger mt-1'>Invalid Configuration formatting</small>}
                            </div>
                        </Form.Group>
                    </div>
                    <Form.Group className='mt-4 row justify-content-end'>
                        <div className="col-md-9">
                            <Button onClick={ handleCancel } variant='outline-primary' size="sm">
                                Cancel
                            </Button>
                            <Button disabled={ !canSave } onClick={ () => handleSubmit() } size='sm' className='ms-2'>
                                Submit
                            </Button>
                            {handleSaveTemplate && 
                            <Button disabled={ !canSaveTemplate } onClick={ () => setShowDeleteModal(true) } variant='danger' size='sm' className='ms-2'>
                                    Save as template
                            </Button>}
                        </div>
                    </Form.Group>
                </Col>
            </Row>

            <Modal show={ showDeleteModal } onHide={ () => {
                setNewTemplateName("")
                setShowDeleteModal(false);
            } } centered>
                <Modal.Header>
                    <Modal.Title>Save as template</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <SimpleBar autoHide={ false } className='simplebar-no-autohide pe-3' style={ { maxHeight: 'calc(100vh - 380px)' } }>
                        <Textbox label="Name" value={ newTemplateName } onChange={ setNewTemplateName } />
                    </SimpleBar>
                </Modal.Body>
                <Modal.Footer>
                    <Button size="sm" variant='secondary' onClick={ () => {
                        setNewTemplateName("")
                        setShowDeleteModal(false);
                    } }>Cancel</Button>
                    <Button disabled={stringUtilities.isNullOrEmpty(newTemplateName)} size="sm" variant="primary" onClick={ () => handleSaveTemplate(newTemplateName) }>Save template</Button>
                </Modal.Footer>
            </Modal>
        </Container>
    )
}