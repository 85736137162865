import { Col, Container, Table, Button, Modal } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom'
import useApi from '../utilities/useApi';
import { useEffect, useState } from 'react';
import Loading from './common/loading';

export default function KMLFiles(props) {
    const navigate = useNavigate();
    const { organisationId, projectId } = useParams();
    const { api } = useApi();
    const [projectName, setProjectName] = useState(null);
    const [kmlFiles, setKmlFiles] = useState([]);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [modalFile, setModalFile] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const fetchKmlFiles = () => {
        setIsLoading(true);
        api.getProject(
            organisationId, projectId,
            data => setProjectName(data.name),
            () => { }
        )
        api.getKmlFiles(
            organisationId, projectId,
            data => { setKmlFiles(data); setIsLoading(false) },
            () => { }
        )
    }

    useEffect(() => fetchKmlFiles(), [])

    const onDelete = () => {
        api.deleteKmlFile(
            organisationId, projectId, modalFile.id,
            () => {
                fetchKmlFiles();
                setShowDeleteModal(false);
                setModalFile(null);
            },
            () => { }
        )
    }

    const onDownload = (downloadUrl) => {
        const downloadLink = document.createElement("a");
        downloadLink.href = downloadUrl;
        downloadLink.download = downloadUrl;
        document.body.appendChild(downloadLink);
        downloadLink.click();
    }

    return (
        <Container className='pt-5 pb-lg-4 mt-5 mb-sm-2'>
            <Col className='mb-5'>
                {
                    projectName &&
                    <div className='d-flex justify-content-between my-5'>
                        <h1 className="h2 m-0">{ projectName } - KML Files</h1>
                        <div className='d-flex align-items-center'>
                            <Button style={ { marginRight: "12px" } } size='sm' variant='outline-primary' onClick={ () => navigate(`/${ props.organisationId }/projects`) }>
                                <span className='d-flex align-items-center'><i className='fi-arrow-left me-2'></i>
                                    Back to Projects
                                </span>
                            </Button>
                            <Button size='sm' onClick={ () => navigate(`/${ organisationId }/projects/${ projectId }/kml-files/add`) }>
                                <span className='d-flex align-items-center'><i className='fi-plus me-2'></i>
                                    Add KML File
                                </span>
                            </Button>
                        </div>
                    </div>
                }
                {
                    !isLoading ? (
                        <Table>
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>&nbsp;</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    kmlFiles && kmlFiles
                                        .map(item => (
                                            <tr key={ item.id }>
                                                <td>{ item.fileName.split("/").pop() }</td>
                                                <td className='text-end'>
                                                    <Button variant='outline-primary' size="sm" onClick={ () => onDownload(item.url) } style={ { marginRight: "8px" } }>
                                                        <span className='d-flex align-items-center'><i className='fi-download me-2'></i> Download</span>
                                                    </Button>
                                                    <Button variant='outline-danger' size="sm" onClick={ () => {
                                                        setModalFile(item)
                                                        setShowDeleteModal(true);
                                                    } }>
                                                        <span className='d-flex align-items-center'><i className='fi-trash me-2'></i> Delete</span>
                                                    </Button>
                                                </td>
                                            </tr>)
                                        )
                                }
                            </tbody>
                        </Table>
                    ) : <Loading />
                }
            </Col>
            {
                modalFile &&
                <Modal show={ showDeleteModal } onHide={ () => {
                    setModalFile(null);
                    setShowDeleteModal(false);
                } } centered>
                    <Modal.Header>
                        <Modal.Title>Are you sure you want to delete the KML File "{ modalFile.fileName.split("/").pop() }"?</Modal.Title>
                    </Modal.Header>
                    <Modal.Footer>
                        <Button size="sm" variant='secondary' onClick={ () => {
                            setModalFile(null);
                            setShowDeleteModal(false);
                        } }>Cancel</Button>
                        <Button size="sm" variant="primary" onClick={ onDelete }>Delete</Button>
                    </Modal.Footer>
                </Modal>
            }
        </Container>
    )
}