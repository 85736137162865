export const ApiLicenceAgreement = () => {
    return (
        <div className="api-licence-agreement-container">
           <div>
                <h1>TERRAFLOW GEOMATICS</h1>
                <h2>API LICENCE AGREEMENT</h2>
           </div>
           <div className="mt-3">
                <div className="mb-4">
                    <h3>CONTRACT DETAILS</h3>
                    <p>This API Licence Agreement is a binding contract between you (<b>“Client”</b> or “you” or “your”) and TerraFlow Geomatics Inc. (<b>“TerraFlow”</b>). This agreement governs Client's access to and use of TerraFlow's proprietary TerraFlow API application programming interface.
                    </p>
                </div>

                <div className="mb-4">
                    <h3>ACCEPTANCE</h3>
                    <p>This agreement takes effect when you first click the “I accept” button below or when you first access or use the TerraFlow API by calling the API or receiving an API Key, whichever is earlier (the <b>“Effective Date”</b>). By clicking the “I accept” button below, or by accessing or using the TerraFlow API, you: (a) acknowledge that you have read and understood this agreement; (b) represent and warrant that you have the right, power and capacity to enter into this agreement (and, if Client is an organization or business entity, that you are an authorized representative of that organization or business entity and that you have the authority to legally bind that organization or business entity to this agreement); and (c) accept this agreement and accept that Client is legally bound by this agreement.
                    </p>
                </div>

                <div>
                    <h3>AGREEMENT</h3>
                    <ol className="agreement-list">
                        <li className="mb-4">
                            <b className="text-primary">Defined Terms</b>
                            <ul className="list-unstyled">
                                <li><b>“API”</b> means the TerraFlow API application programming interface and any API Documentation or other API materials made available by TerraFlow on the Developer Site.</li>
                                <li><b>“API Documentation”</b> means the technical documentation, usage guidelines, and other documentation relating to the API maintained by TerraFlow at the Developer Site from time to time.</li>
                                <li><b>“API Key”</b> means the security key TerraFlow makes available for you to access the API.</li>
                                <li><b>“Application”</b> means any software applications developed by you to interact with the API.</li>
                                <li><b>“Authorized User”</b> means any Client employee or contractor that Client authorizes to access and use the API in order to develop, test, operate or support the Application.</li>
                                <li><b>“Developer Site”</b> means TerraFlow's website at <a href="https://www.terraflow.ca/" target="_blank">www.TerraFlow.ca</a>, dataengine.TerraFlow.ca, app.TerraFlow.ca or api.TerraFlow.ca.</li>
                                <li><b>“TerraFlow Marks”</b> means TerraFlow's proprietary trademarks, trade names, branding, or logos made available for use in connection with the API under this agreement.</li>
                                <li><b>“TerraFlow Solution”</b> means the technology and application software that TerraFlow makes available on a hosted basis as listed and described at the Developer Site.</li>
                            </ul>
                        </li>

                        <li className="mb-4">
                            <b className="text-primary">Licence</b>
                            <p>Subject to this agreement's terms and conditions and Client's compliance with this agreement, TerraFlow grants Client a limited, revocable, non-exclusive, non-sublicensable, non-transferable, non-assignable license during this agreement's term to: (a) access and use the API to develop, test, operate, and support your Application that will communicate and interoperate with the TerraFlow Solution; (b) distribute or allow access to Client's integration of the API within the Application to end users of the Application; (c) access and use the API Documentation for the purposes described above; and (d) display certain TerraFlow Marks in compliance with usage guidelines that TerraFlow may specify from time to time solely in connection with the use of the API and the Applications (and not in connection with the advertising, promotion, distribution, or sale of any other products or services).</p><br />
                            <p>You acknowledge that there are no implied licences granted under this agreement. TerraFlow reserves all rights that are not expressly granted in this agreement.</p>
                        </li>

                        <li className="mb-4">
                            <b className="text-primary">API Documentation</b>
                            <p>Client will comply with the API Documentation when accessing and using the API.</p>
                        </li>

                        <li className="mb-4">
                            <b className="text-primary">API Keys</b>
                            <p>Client must obtain an API Key through the registration process available at the Developer Site after accepting this agreement to access and use the API. Client will access the API only through the applicable API Key. Client will not share any API Key with any third party, will keep the API Key and all log-in information secure, and will use the API Key as Client's sole means of accessing the API. TerraFlow may revoke an API Key at any time.</p>
                        </li>

                        <li className="mb-4">
                            <b className="text-primary">Authorized Users</b>
                            <p>Client's obligations under this agreement apply equally to all Authorized Users, and Client is responsible for ensuring that all Authorized Users comply with all those obligations. Any act or omission by an Authorized User that would constitute a breach of this agreement if taken by Client will be deemed Client's breach of this agreement.</p><br />
                            <p>Notwithstanding any other wording in this agreement, Client will not allow any Authorized User that is, or that is employed or retained by, a TerraFlow competitor to access or use the API.</p>
                        </li>

                        <li className="mb-4">
                            <b className="text-primary">Use Restrictions</b>
                            <p>Except as expressly authorized under this agreement, Client will not:</p>
                            <ol type="a">
                                <li>copy, modify, or create derivative works of the API, in whole or in part;</li>
                                <li>rent, lease, lend, sell, license, sublicense, assign, distribute, publish, transfer, or otherwise make available the API;</li>
                                <li>reverse engineer, disassemble, decompile, decode, adapt, or otherwise attempt to derive or gain access to any software component of the API, in whole or in part;</li>
                                <li>remove or change any proprietary notices from the API;</li>
                                <li>use the API in any manner or for any purpose that infringes, misappropriates, or otherwise violates any intellectual property right or other right of any person, or that violates any applicable law;</li>
                                <li>use the API in a manner that, as determined by TerraFlow in its sole discretion, exceeds reasonable request volume, constitutes excessive or abusive usage, or otherwise fails to comply or is inconsistent with any part of the API Documentation;</li>
                                <li>combine or integrate the API with any software, technology, services, or materials not authorized by TerraFlow;</li>
                                <li>avoid, circumvent, or disable any access control technology, security device, procedure, protocol, or technological protection mechanism that may be included or established in or as part of the API or any hardware/software used to provide the API, or third-party hardware, software or services;</li>
                                <li>conduct or instigate any denial of service attack against TerraFlow's websites, systems or network, or any third party's website, systems or networks;</li>
                                <li>design or permit the Application to disable, override, or otherwise interfere with any TerraFlow-implemented communications to end users, consent screens, user settings, alerts, warning, or the like;</li>
                                <li>use the API in the Applications to replicate or attempt to replace the user experience of the TerraFlow Solution; or</li>
                                <li>attempt to cloak or conceal Client's identity or the Application's identity when requesting authorization to access and use the API.</li>
                            </ol>

                            <p>Client will comply with all (i) all guidelines, standards, and requirements that may be posted on the Developer Site from time to time, (ii) terms and conditions in this agreement, and (iii) applicable laws, rules, and regulations. In addition, Client will not use the API in connection with or to promote any products, services, or materials that constitute, promote, or are used primarily for the purpose of dealing in spyware, adware, or other malicious programs or code, counterfeit goods, items subject to Canadian embargo, unsolicited mass distribution of email (“spam”), unconsented to commercial electronic messages (CEMs), multi-level marketing proposals, hate materials, hacking, surveillance, interception, or descrambling equipment, libelous, defamatory, obscene, pornographic, abusive, or otherwise offensive or illegal content, stolen products, and items used for theft, hazardous materials, or any illegal activities.</p><br />
                            <p>In addition to any other available remedies, TerraFlow may suspend or terminate Client's access to and use of the API if Client breaches of any of these restrictions. Further, regardless of Client's efforts, Client acknowledges that TerraFlow will not be responsible for any harm caused in whole or part by any Authorized User's failure to comply with this agreement.</p>
                        </li>

                        <li className="mb-4">
                            <b className="text-primary">Application</b>
                            <p>Client is solely responsible for: (a) developing, testing, debugging, hosting, providing, operating, updating, supporting, and maintaining the Application and integrating the Application with the API; and (b) supplying, configuring and maintaining the hardware, software, licences, telecommunication and internet equipment, connections and services necessary for providing and maintaining the hardware and operating system platform and internet connections that are appropriate and necessary for hosting, providing and operating the Application. Without limiting the previous sentence, Client acknowledges that it may need third-party development tools, compilers and other software and technology to use the API effectively, and that Client is solely responsible for procuring any such third-party software or technology and the necessary licences to use that software or technology. TerraFlow makes no representation or warranties regarding, and will have no obligation or liability regarding, any such third-party software or technology.</p><br />
                            <p>Client will monitor the use of the Application for any activity that violates this agreement or any applicable laws, rules, and regulations, including any fraudulent, inappropriate, or potentially harmful behaviour, and promptly restrict any offending users of the Application from further use of the Application.</p><br />
                            <p>Client will provide a resource for Application users to report abuse of the Applications. As between Client and TerraFlow, Client is responsible for all acts and omissions of Client's end users in connection with the Application and their use of the API, if any. Client agrees that Client is solely responsible for posting any privacy notices and obtaining any consents from Client's end users required under applicable laws, rules, and regulations for their use of the Application.</p><br />
                            <p>As between TerraFlow and Client, Client is responsible for all acts and omissions of its end users in connection with the Application and their use of the API, if any.</p>
                        </li>

                        <li className="mb-4">
                            <b className="text-primary">Privacy Compliance</b>
                            <p>Client will comply with all applicable privacy and other laws in connection with all information Client collects through the Application or otherwise passes through the API, and all content passed through the API to the Application. Without limiting the previous sentence, Client is solely responsible for posting any privacy notices and obtaining any consents from end users required under applicable laws for their use of the Application.</p>
                        </li>

                        <li className="mb-4">
                            <b className="text-primary">TerraFlow Marks</b>
                            <p>All Client's use of the TerraFlow Marks, if any, will comply with any usage guidelines that TerraFlow may specify from time to time. Client's use of the TerraFlow Marks in connection with this Agreement will not create any right, title, or interest in or to the TerraFlow Marks in favour of Client, and all goodwill associated with the use of the TerraFlow Marks will inure to TerraFlow's benefit.</p>
                        </li>

                        <li className="mb-4">
                            <b className="text-primary">Proprietary Rights</b>
                            <p>As between the parties, TerraFlow owns all rights, title, and interest in and to the API, to all output and executables of the API, and to the TerraFlow Solution and, subject to the foregoing, Client owns all rights, title, and interest in and to the Application.  Except to the limited extent expressly provided in this agreement, neither party grants, and the other party shall not acquire, any right, title or interest (including, without limitation, any implied license) in or to any property of the first party. All rights not expressly granted in this agreement are deemed withheld.</p><br />
                            <p>If Client provides any feedback or suggestions about the API to TerraFlow, then TerraFlow may use that feedback or suggestions without restriction and without obligation to Client.</p><br />
                            <p>Client will use commercially reasonable efforts to safeguard the API (including all copies) from infringement, misappropriation, theft, misuse, or unauthorized access. Client will promptly notify TerraFlow upon becoming aware of any infringement of any intellectual property rights in the API and will fully cooperate with TerraFlow in any legal action taken that TerraFlow takes to enforce its intellectual property rights.</p>
                        </li>

                        <li className="mb-4">
                            <b className="text-primary">No Support; Updates</b>
                            <p>This agreement does not entitle Client to any support for the API.</p><br />
                            <p>Client will report to TerraFlow any errors or difficulties discovered with respect to the API and the characteristic conditions and symptoms of those errors and difficulties. TerraFlow is in no way obligated to provide Client with any error correction or support but may provide whatever error correction and/or support services TerraFlow may determine in its sole discretion (and anything it provides in connection therewith will be deemed part of the API).</p><br />
                            <p>Client acknowledges that TerraFlow may (but has no obligation to) from time to time release updates, bug fixes, patches, or other error corrections to the API (<b>“Updates”</b>), and may require Client to obtain and use the most recent version of the API. Each Update is part of the API and is subject to this agreement. TerraFlow tries to ensure that Updates are backwards compatible, but Client acknowledges that Updates may adversely affect how the Application communicates with the Company Solution or otherwise functions. Client will make any changes to the Application that are required for integration as a result of such Update at Client's sole cost and expense. Client's continued use of the API following an Update constitutes binding acceptance of the Update.</p>
                        </li>
                        
                        <li className="mb-4">
                            <b className="text-primary">No Fees</b>
                            <p>Client acknowledges and agrees that</p>
                            <ol type="a">
                                <li>no licence fees or other payments will be due under this agreement in exchange for the rights granted under this agreement; and</li>
                                <li>this fee arrangement is made in consideration of the mutual covenants set forth in this agreement, including, without limitation, the disclaimers, exclusions, and limitations of liability set out in this agreement.</li>
                            </ol>
                        </li>

                        <li className="mb-4">
                            <b className="text-primary">Data Activities</b>
                            <p>Each party will retain exclusive ownership of any information or data (<b>“Data”</b>) that party created, authored, or obtained through a third party before the Effective Date. </p><br />
                            <p>If Client submits Client Data to the API and TerraFlow Solution, then Client grants to TerraFlow a limited, worldwide, non-transferable, royalty-free, right and licence (with right of sub-license where required to make available and operate the API and TerraFlow Solution) to use that Data solely for the purpose of providing the API and TerraFlow Solution. Client represents and warrants that Client has all rights necessary to grant this licence and that TerraFlow's use of that Data will not infringe, violate or misappropriate on the Data ownership rights or other proprietary rights of any third party.</p><br />
                            <p>Client is solely responsible for (and TerraFlow has no responsibility to Client or to any third party for) any Data that Client's create, transmit or display on or through the API, the TerraFlow Solution, or the Application, and for the consequences of Client's actions (including any loss or damage that TerraFlow may suffer) by doing so.</p><br />
                            <p>TerraFlow may collect certain information through the API or the TerraFlow Solution about Client or its end users or any of Client's or its end users' employees, contractors, or agents. By accessing, using, and providing information to or through the API or the TerraFlow Solution, Client consent to all actions taken by TerraFlow with respect to this information in compliance with the then-current version of TerraFlow's privacy policy, available at <a href="https://www.terraflow.ca/privacy-policy" target="_blank">https://www.terraflow.ca/privacy-policy</a>, and all applicable privacy laws.</p><br />
                            <p>TerraFlow will own all analytic information created as part of providing the API or through Client's access to and use of the API and the TerraFlow Solution.</p><br />
                            <p>TerraFlow may collect, use, modify and create aggregated, non-identifiable data, including derivatives of statistical or technical information and data, generated by, derived from or relating to Client's and its end users' use of the API, the TerraFlow Solution, and Application in order to: (i) to provide, operate, maintain, optimize, improve and support the API and TerraFlow's business (including to develop new products, services, functionality and features); (ii) for record keeping, fee calculation, internal reporting, support and other internal business purposes; and (iii) to advertise, market and promote TerraFlow business, products and services.</p>
                        </li>

                        <li className="mb-4">
                            <b className="text-primary">Disclaimer of Warranties</b>
                            <p>THE API AND TERRAFLOW MARKS ARE PROVIDED "AS IS" AND TERRAFLOW SPECIFICALLY DISCLAIMS ALL REPRESENTATIONS, WARRANTIES AND CONDITIONS, WHETHER EXPRESS, IMPLIED, STATUTORY, OR OTHERWISE. TERRAFLOW SPECIFICALLY DISCLAIMS ALL IMPLIED WARRANTIES AND CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, NON-INFRINGEMENT, QUALITY, PERFORMANCE, AVAILABILITY, OR INTEGRITY OF DATA, AND ALL WARRANTIES ARISING FROM COURSE OF DEALING, USAGE, OR TRADE PRACTICE. TERRAFLOW MAKES NO WARRANTY OF ANY KIND THAT THE API, THE API DOCUMENTATION, THE DEVELOPER SITE, THE TERRAFLOW SOLUTION, ANY DATA PROVIDED THROUGH THE API OR TERRAFLOW SOLUTION, OR THE TERRAFLOW MARKS, OR ANY PRODUCTS OR RESULTS OF USING ANY OF THOSE THINGS, WILL BE AVAILABLE, UNINTERRUPTED, TIMELY, SECURE, ACCURATE, COMPLETE OR ERROR-FREE, OR WILL MEET ANY REQUIREMENTS OR CLIENT'S EXPECTATIONS, WILL ACHIEVE ANY INTENDED RESULT, BE COMPATIBLE OR WORK WITH ANY OF CLIENT'S OR ANY THIRD PARTY'S SOFTWARE, SYSTEM OR OTHER SERVICES, OR BE SECURE, ACCURATE, COMPLETE, FREE OF HARMFUL CODE, OR THAT ANY ERRORS OR DEFECTS CAN OR WILL BE CORRECTED.</p>
                        </li>

                        <li className="mb-4">
                            <b className="text-primary">Limitation of Liability</b>
                            <p>TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT WILL TERRAFLOW OR ANY OF ITS AFFILIATES, LICENSORS, SUPPLIERS OR SUBCONTRACTORS, OR ANY OF THEIR SUCCESSORS, ASSIGNS, OFFICERS, DIRECTORS, EMPLOYEES, OR AGENTS HAVE ANY LIABILITY TO CLIENT, ANY AUTHORIZED USER, OR ANY OTHER PERSON OR ENTITY FOR ANY OF THE FOLLOWING DAMAGES ARISING FROM OR RELATED TO THIS AGREEMENT OR THE API, THE API DOCUMENTATION, THE DEVELOPER SITE, THE TERRAFLOW SOLUTION, ANY DATA PROVIDED THROUGH THE API OR TERRAFLOW SOLUTION, OR THE TERRAFLOW MARKS, WHETHER ARISING FROM CONTRACT (INCLUDING FUNDAMENTAL BREACH), EQUITY, TORT (INCLUDING NEGLIGENCE), PRODUCT LIABILITY, STRICT LIABILITY, OR ANY OTHER LEGAL OR EQUITABLE THEORY: (A) ANY INDIRECT, RELIANCE, INCIDENTAL, SPECIAL, PUNITIVE, EXEMPLARY OR CONSEQUENTIAL DAMAGES, EVEN IF TERRAFLOW (INCLUDING ITS AFFILIATES, LICENSORS, SUPPLIERS OR SUBCONTRACTORS) HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, OR THEY ARE FORESEEABLE; OR (B) LOSS OF REVENUE OR PROFIT, LOSS OF OR DAMAGE TO DATA, BUSINESS INTERRUPTION, REPLACEMENT OR RECOVERY COSTS, OR OTHER COMMERCIAL OR ECONOMIC LOSS; OR (C) ANY DAMAGES, IN THE AGGREGATE, IN EXCESS OF CAD $50. TERRAFLOW WILL NOT BE RESPONSIBLE FOR ANY MATTER BEYOND ITS REASONABLE CONTROL. ANY CLAIM CLIENT MAY HAVE ARISING OUT OF OR RELATING TO THIS AGREEMENT MUST BE BROUGHT WITHIN 12 MONTHS AFTER THE OCCURRENCE OF THE EVENT GIVING RISE TO THAT CLAIM.</p>
                        </li>

                        <li className="mb-4">
                            <b className="text-primary">Indemnity</b>
                            <p>Client will indemnify, defend, and hold harmless TerraFlow and its affiliates, licensors, suppliers and subcontractors, and their successors, assigns, officers, directors, employees, and agents, from and against any losses, damages, liabilities, deficiencies, claims, actions, judgments, settlements, interest, awards, penalties, fines, costs, or expenses of whatever kind, including reasonable legal fees and costs, arising from or relating to (a) the use or misuse of the API or Company Trademarks, (b) Client's breach of this agreement, and (c) the Application, including any end user's use of the Application.</p><br />
                            <p>TerraFlow will promptly notify Client in writing of any claims for which TerraFlow seeks indemnification or defence. TerraFlow reserves the right, at its option and in its sole discretion, to assume full control of the defence of claims with legal counsel of TerraFlow's choice. In all cases, Client will obtain TerraFlow's consent, before entering into a settlement or compromise or consenting to any injunctive relief with respect to any claim if the settlement or compromise or injunctive relief would impose any pecuniary or other liability upon TerraFlow or would require TerraFlow to admit any civil or criminal liability.  If TerraFlow assumes control of the defence of any claim, TerraFlow will not settle the claim requiring payment from Client without Client's prior written approval.</p>
                        </li>

                        <li className="mb-4">
                            <b className="text-primary">Term and Termination</b>
                            <p>This agreement's term begins on the Effective Date and will continue in effect until terminated as set forth below:</p>
                            <ol type="a">
                                <li><b>Termination by TerraFlow:</b> TerraFlow may immediately terminate or suspend this agreement, or any rights granted under this agreement, in TerraFlow's sole discretion at any time and for any reason, by providing notice to Client or revoking access to the API and TerraFlow Marks.</li>
                                <li><b>Automatic Termination:</b> This agreement will terminate immediately and automatically without any notice if Client violates any of this agreement's terms and conditions.</li>
                                <li><b>Termination by Client:</b> Client may terminate this agreement at any time by ceasing Client's access to and use of the API and TerraFlow Marks.</li>
                            </ol>
                            <p>If this agreement terminates or expires for any reason then: (a) the licences granted under section 2 will immediately terminate; (b) Client will immediately stop using the API, sever all connections between the Application and the API, and destroy, and permanently erase from all devices and systems Client directly or indirectly controls all copies of the API and TerraFlow Marks; (c) all of Client's payment obligations under this agreement will become due immediately, and Client will immediately pay any outstanding amounts owed to TerraFlow under this agreement; (d) this agreement's termination will not limit any other right or remedy either party may have with respect to liabilities accruing before the effective date of the termination; (e) all of this agreement's provisions that are reasonably necessary to interpret or give effect to, this agreement will survive; and (f) termination will not limit any of TerraFlow's rights or remedies at law or in equity.</p>
                        </li>

                        <li className="mb-4">
                            <b className="text-primary">Export Controls</b>
                            <p>Client will comply with all applicable export laws and restrictions and regulations, and will not export, or allow the export or re-export of, the API in violation of any applicable restrictions, laws or regulations. Client represents and warrants that Client is not located in, under the control of, or a national or resident of any restricted country.</p>
                        </li>

                        <li className="mb-4">
                            <b className="text-primary">Governing Law and Jurisdiction</b>
                            <p>This agreement will be governed by and construed in accordance with the laws applicable in Ontario (including federal Canadian law applicable in Ontario), without reference to conflicts of law principles. The parties consent to the exclusive jurisdiction of the superior and federal courts sitting in Toronto, Ontario in relation to any dispute arising under this agreement. Despite the previous sentence, TerraFlow may apply to a court of competent jurisdiction for interim protection or interim equitable relief such as an interlocutory or interim injunction.</p>
                        </li>

                        <li className="mb-4">
                            <b className="text-primary">Name Reference</b>
                            <p>TerraFlow may refer to Client as a TerraFlow client in TerraFlow's marketing materials that TerraFlow presents to prospective customers, and in TerraFlow's websites, emails, or other promotional material, and Client agrees to act as a customer reference for TerraFlow to prospective TerraFlow customers.</p>
                        </li>

                        <li className="mb-4">
                            <b className="text-primary">Changes to Agreement</b>
                            <p>TerraFlow may, in its sole discretion, modify this agreement from time to time. TerraFlow will notify Client of changes to this agreement through notifications or posts on the Developer Site, and the modifications will become effective 30 days after they are posted on the Developer Site. Client will be responsible for reviewing and becoming familiar with any such modifications.</p>
                        </li>

                        <li className="mb-4">
                            <b className="text-primary">General</b>
                            <p><span className="text-decoration-underline">Entire Agreement</span>. This agreement constitutes the entire agreement between the parties regarding its subject-matter and supersedes all prior agreements, understandings, negotiations and discussions between the parties regarding that subject-matter.</p><br />
                            <p><span className="text-decoration-underline">Independent Contractors</span>.  The parties are independent contractors, and nothing in this agreement creates a partnership, joint venture or agency relationship between them.</p><br />
                            <p><span className="text-decoration-underline">Waiver</span>. A party's failure to insist on the strict performance of any term of this agreement or to exercise any right or remedy in this agreement will not be construed as a waiver of that term, right or remedy.</p><br />
                            <p><span className="text-decoration-underline">Severability</span>. If any portion of this agreement is invalid or unenforceable, the invalidity or unenforceability will attach only to that portion of the agreement, and the remainder of the agreement will remain in full force and effect.</p><br />
                            <p><span className="text-decoration-underline">Assignment and Successors</span>. Client will not assign this agreement or any rights, duties or obligations under this agreement, whether voluntarily or involuntarily or by operation of law, to any other person or entity without TerraFlow's prior written consent, and any attempt to do so without TerraFlow's consent will be void. Subject to the previous sentence, this agreement will bind and benefit each party and its lawful successors and permitted assigns. </p><br />
                            <p><span className="text-decoration-underline">Notices</span>. Any notices to TerraFlow must be sent to TerraFlow's corporate headquarters address available at TERRAFLOW GEOMATICS, INC,. BOX 28023 TERRYTOWN PO, SCARBOROUGH, ONTARIO, M1N 4E7 CANADA and must be delivered either in person, by certified or registered mail by Canada Post, return receipt requested and postage prepaid, or by recognized overnight courier service, and are deemed given upon receipt by TerraFlow. Despite the previous sentence, Client hereby consents to receiving electronic communications from TerraFlow (which electronic communications may include notices about applicable fees and charges, transactional information, and other information concerning or related to the API). Client agrees that any notices, agreements, disclosures, or other communications that TerraFlow sends to Client electronically will satisfy any legal communication requirements, including that communications be in writing.</p>
                        </li>
                    </ol>
                </div>
           </div>
        </div>
    )
}