
import { useEffect, useState } from "react";
import { useParams, useNavigate } from 'react-router-dom'
import useApi from "../utilities/useApi";
import stringUtilities from "../utilities/stringUtilities";
import { ProjectAddEdit } from "./common/formsCreateEdit/projectAddEdit";

export default function EditProject() {
    const navigate = useNavigate();
    const { api } = useApi();
    const { organisationId, projectId } = useParams();
    const [name, setName] = useState("");
    const [syncDataDays, setSyncDataDays] = useState("0");
    const [canSave, setCanSave] = useState(false);
    const [center, setCenter] = useState(null);
    const [mapOutputWidth, setMapOutputWidth] = useState("0");
    const [mapOutputHeight, setMapOutputHeight] = useState("0");
    const [mapMarkersAsDots, setMapMarkersAsDots] = useState(false);

    useEffect(() => {
        api.getProjects(
            organisationId,
            (data) => {
                const project = data.find(item => item.id === projectId);
                setName(project.name);
                setCenter({
                    lat: project.latitude,
                    lng: project.longitude,
                })
                setMapOutputWidth(String(project.configuration.mapOutputWidth))
                setMapOutputHeight(String(project.configuration.mapOutputHeight))
                setSyncDataDays(String(project.syncDataDays))
                setMapMarkersAsDots(project.configuration.mapConfiguration.mapMarkersAsDots)
            },
            () => { }
        )
    }, [])

    useEffect(() => {
        setCanSave(!stringUtilities.isNullOrWhiteSpace(name) && !stringUtilities.isNullOrEmpty(syncDataDays))
    }, [name, syncDataDays])

    const handleSubmit = () => {
        const configuration = {
            mapConfiguration: {
                mapMarkersAsDots: mapMarkersAsDots,
            },
            mapOutputWidth: parseInt(mapOutputWidth), 
            mapOutputHeight: parseInt(mapOutputHeight)
        }
        api.editProject(
            organisationId,
            projectId,
            name,
            parseFloat(center.lng), parseFloat(center.lat),
            parseInt(syncDataDays), configuration,
            () => { navigate(`/${ organisationId }/projects`) },
            () => { }
        )
    }

    return (
        <ProjectAddEdit 
            title="Edit Project" 
            name={name}
            setName={setName}
            syncDataDays={syncDataDays}
            setSyncDataDays={setSyncDataDays}
            center={center}
            setCenter={setCenter}
            mapOutputWidth={mapOutputWidth}
            setMapOutputWidth={setMapOutputWidth}
            mapOutputHeight={mapOutputHeight}
            setMapOutputHeight={setMapOutputHeight}
            canSave={canSave}
            handleSubmit={handleSubmit}
            handleCancel={() => navigate(`/${ organisationId }/projects`) }
        />
    )
}