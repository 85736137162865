import { useEffect, useState, useRef } from "react";
import { WorkflowAddEdit } from "../components/common/formsCreateEdit/workflowAddEdit";
import useApi from "../utilities/useApi";
import { useParams, useNavigate } from 'react-router-dom';
import stringUtilities from "../utilities/stringUtilities";
import { Modal, Button } from "react-bootstrap";
import SimpleBar from "simplebar-react";
import Textbox from "./form/textbox";

export default function EditWorkflow() {
    const { api } = useApi();
    const { organisationId, projectId, workflowId } = useParams();
    const navigate = useNavigate();
    const [name, setName] = useState("");
    const [newTemplateName, setNewTemplateName] = useState("");
    const [isComposite, setIsComposite] = useState(false);
    const [workflowTemplateId, setWorkflowTemplateId] = useState(null);
    const [workflowTemplates, setWorkflowTemplates] = useState([]);
    const [workflowType, setWorkflowType] = useState("9");
    const [hue, setHue] = useState(0);
    const [rgbColor, setRgbColor] = useState(null);
    const [order, setOrder] = useState("0");
    const [configuration, setConfiguration] = useState(null);
    const [canSave, setCanSave] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [isValidJson, setIsValidJson] = useState(true);
    
    const changeConfigurationTimeout = useRef();
    const handleConfigurationChange = function (value) {
        if (stringUtilities.isNullOrEmpty(value)) {
            setConfiguration(null);
            setIsValidJson(true)
            return  
        }
        setConfiguration(value);
        stringUtilities.validateJsonWithDebouncer(value, changeConfigurationTimeout, setIsValidJson)
    };    

    const initialiseWorkflowTypeValue = (type) => {
        return (type !== 9 && type !== 10) ? 9 : type
    }

    const fetchWorkflowData = () => {
        api.getWorkflow(
            organisationId, projectId, workflowId,
            (data) => {
                setName(data.name);
                setIsComposite(data.isComposite);
                setWorkflowType(String(initialiseWorkflowTypeValue(data.workflowType)));
                setHue(data.hue);
                setRgbColor(stringUtilities.checkColorHash(data.color));
                setOrder(data.orderBy);
                setConfiguration(data.configuration);
            },
            () => { }
        )
    }

    useEffect(() => {
        fetchWorkflowData();

        api.getWorkflowTemplates(
            organisationId,
            data => setWorkflowTemplates(data),
            () => { }
        )
    }, [])


    const handleSubmit = () => {
        api.editWorkflow(
            organisationId, projectId, workflowId,
            name, parseInt(order), configuration, parseInt(workflowType), parseInt(hue), rgbColor, workflowTemplateId, isComposite,
            () => navigate(`/${ organisationId }/projects/${ projectId }/workflows`),
            () => { }
        )
    }

    const onSaveTemplate = (newTemplateName) => {
        api.addWorkflowTemplate(
            organisationId,
            newTemplateName, parseInt(order), configuration, parseInt(workflowType), parseInt(hue), rgbColor,
            () => navigate(`/${ organisationId }/projects/${projectId}/workflows`),
            () => {},
        )
    }

    const handleWorkflowTemplateChange = (templateId) => {
        if (!stringUtilities.isNullOrEmpty(templateId)) {
            setWorkflowTemplateId(templateId);
            const selectedTemplate = workflowTemplates.find(item => item.id === templateId);
            setWorkflowType(String(initialiseWorkflowTypeValue(selectedTemplate.workflowTypeId)));
            setHue(selectedTemplate.hue);
            setRgbColor(stringUtilities.checkColorHash(selectedTemplate.color));
            setOrder(String(selectedTemplate.orderBy));
            setConfiguration(selectedTemplate.configuration ?? null);
        } else {
            setWorkflowTemplateId(null);
            fetchWorkflowData();
        }
    }

    useEffect(() => setCanSave(!stringUtilities.isNullOrEmpty(name) && isValidJson), [name, isValidJson])

    return (
        <>
            <WorkflowAddEdit
                title="Edit workflow"
                name={ name }
                setName={ setName }
                isComposite={ isComposite }
                workflowTemplates={ workflowTemplates }
                setIsComposite={ setIsComposite }
                workflowTemplateId={ workflowTemplateId }
                handleWorkflowTemplateChange={ templateId => handleWorkflowTemplateChange(templateId) }
                workflowType={ workflowType }
                setWorkflowType={ setWorkflowType }
                hue={ hue }
                setHue={ setHue }
                rgbColor={ rgbColor }
                setRgbColor={ setRgbColor }
                order={ order }
                setOrder={ setOrder }
                configuration={ configuration }
                handleConfigurationChange={ value => handleConfigurationChange(value) }
                canSave={ canSave }
                handleSubmit={ handleSubmit }
                handleCancel={ () => navigate(`/${ organisationId }/projects/${ projectId }/workflows`) }
                canSaveTemplate={stringUtilities.isNullOrEmpty(workflowTemplateId) && order !== undefined && isValidJson}
                handleSaveTemplate={ newTemplateName => onSaveTemplate(newTemplateName)}
                isValidJson={isValidJson}
            />
        </>
    )
}