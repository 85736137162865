import { useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { ApiKeyAddEdit } from './common/formsCreateEdit/apiKeyAddEdit';
import useApi from '../utilities/useApi';
import { useParams, useNavigate } from 'react-router-dom';
import stringUtilities from '../utilities/stringUtilities';
import Loading from './common/loading';

export default function EditApiKey(props) {
    const navigate = useNavigate();
    const { api } = useApi();
    const { organisationId, apiKeyId } = useParams();
    const [apiKeyName, setApiKeyName] = useState("");
    const [roleId, setRoleId] = useState(null);
    const [expiresAt, setExpiresAt] = useState(null);
    const [status, setStatus] = useState(null);
    const [canSave, setCanSave] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const fetchAllApiKeys = () => {
        setIsLoading(true);
        api.getApiKeys(
            organisationId,
            data => {
                const apiKey = data.find(item => item.id === apiKeyId);
                setApiKeyName(apiKey.name);
                setRoleId(apiKey.role);
                setExpiresAt(apiKey.expiresAt);
                setStatus(apiKey.isActive);
                setIsLoading(false);
            },
            error => console.log(error)
        )
    }

    useEffect(() => fetchAllApiKeys(), [])

    useEffect(() => setCanSave(!stringUtilities.isNullOrEmpty(apiKeyName) && roleId !== undefined), [apiKeyName, roleId])

    const handleSubmit = () => {
        api.updateApiKey(
            organisationId, apiKeyId,
            apiKeyName, roleId, expiresAt, status,
            () => navigate(`/organisations/${ organisationId }/api-keys`),
            error => console.log(error)
        )
    }

    return (
        <Container className='pt-5 pb-lg-4 mt-5 mb-sm-2'>
            <Row>
                <Col className='mb-5'>
                    <div className='d-flex justify-content-between my-5'>
                        <h1 className="h2 m-0">Edit API Key</h1>
                    </div>
                    {
                        isLoading
                            ? <Loading />
                            : <ApiKeyAddEdit
                                apiKeyName={ apiKeyName }
                                setApiKeyName={ setApiKeyName }
                                roleId={ roleId }
                                setRoleId={ setRoleId }
                                expiresAt={ expiresAt }
                                setExpiresAt={ setExpiresAt }
                                status={ status }
                                setStatus={ setStatus }
                                canSave={ canSave }
                                onCancel={ () => navigate(`/organisations/${ organisationId }/api-keys`) }
                                onSave={ () => handleSubmit() }
                                userRoleId={props.user.roleId}
                            />

                    }
                </Col>
            </Row>
        </Container>
    )
}