import { Col, Container, Table, Button, Modal } from 'react-bootstrap';
import { useNavigate, useParams, Link } from 'react-router-dom'
import useApi from '../utilities/useApi';
import { useEffect, useState } from 'react';
import stringUtilities from '../utilities/stringUtilities';
import Loading from './common/loading';

export default function Surfaces(props) {
    const navigate = useNavigate();
    const { organisationId, projectId } = useParams();
    const { api } = useApi();
    const [projectName, setProjectName] = useState(null);
    const [surfaces, setSurfaces] = useState([]);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [modalSurface, setModalSurface] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const fetchSurfaces = () => {
        setIsLoading(true);
        api.getSurfaces(
            organisationId, projectId,
            data => { setSurfaces(data); setIsLoading(false) },
            () => { }
        )
    }

    useEffect(() => {
        api.getProject(
            organisationId, projectId,
            data => setProjectName(data.name)
        )
        fetchSurfaces()
    }, [])

    const onDelete = () => {
        api.deleteSurface(
            organisationId, projectId, modalSurface.id,
            () => {
                fetchSurfaces();
                setShowDeleteModal(false);
                setModalSurface(null);
            },
            () => { }
        )
    }
    return (
        <Container className='pt-5 pb-lg-4 mt-5 mb-sm-2'>
            {
                (
                    <Col className='mb-5'>
                        <div className='d-flex justify-content-between my-5'>
                            {
                                projectName && (
                                    <>
                                        <h1 className="h2 m-0">{ projectName } - Surfaces</h1>
                                        <div className='d-flex align-items-center'>
                                            <Button style={ { marginRight: "12px" } } size='sm' variant='outline-primary' onClick={ () => navigate(`/${ props.organisationId }/projects`) }>
                                                <span className='d-flex align-items-center'><i className='fi-arrow-left me-2'></i>
                                                    Back to Projects
                                                </span>
                                            </Button>
                                            <Button size='sm' onClick={ () => navigate(`/${ organisationId }/projects/${ projectId }/surfaces/add`) }>
                                                <span className='d-flex align-items-center'><i className='fi-plus me-2'></i>
                                                    Add Surface
                                                </span>
                                            </Button>
                                        </div>
                                    </>
                                )
                            }
                        </div>
                        {
                            !isLoading ? (
                                <Table>
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th className='text-end'>Triangles</th>
                                            <th>&nbsp;</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            surfaces && surfaces
                                                .map(item => (
                                                    <tr key={ item.id }>
                                                        <td>{ item.name }</td>
                                                        <td className='text-end'>{ stringUtilities.formatDecimal(item.surfaceTriangles.length, 0) }</td>
                                                        <td className='text-end'>
                                                            <Link to={ `/${ organisationId }/projects/${ projectId }/surfaces/${ item.id }/edit` } variant='secondary' size="sm" className='me-2 btn btn-light-primary shadow-sm btn-sm'>
                                                                <span className='d-flex align-items-center'><i className='fi-edit me-2'></i> Edit</span>
                                                            </Link>
                                                            <Button variant='outline-danger' size="sm" onClick={ () => {
                                                                setModalSurface(item)
                                                                setShowDeleteModal(true);
                                                            } }>
                                                                <span className='d-flex align-items-center'><i className='fi-trash me-2'></i> Delete</span>
                                                            </Button>
                                                        </td>
                                                    </tr>)
                                                )
                                        }
                                    </tbody>
                                </Table>
                            ) : <Loading />
                        }
                    </Col>
                )
            }
            {
                modalSurface &&
                <Modal show={ showDeleteModal } onHide={ () => {
                    setModalSurface(null);
                    setShowDeleteModal(false);
                } } centered>
                    <Modal.Header>
                        <Modal.Title>Are you sure you want to delete the Surface "{ modalSurface.name }"?</Modal.Title>
                    </Modal.Header>
                    <Modal.Footer>
                        <Button size="sm" variant='secondary' onClick={ () => {
                            setModalSurface(null);
                            setShowDeleteModal(false);
                        } }>Cancel</Button>
                        <Button size="sm" variant="primary" onClick={ onDelete }>Delete</Button>
                    </Modal.Footer>
                </Modal>
            }
        </Container>
    )
}