import { Form, Modal, Button } from "react-bootstrap";
import stringUtilities from "../../../utilities/stringUtilities";
import { useState } from "react";

export const EditMapFeature = ({ workflows, selectedWorkflow, setSelectedWorkflow, formData, selectedFeatureType, setSelectedFeatureType, featureTypes, featureFormDataFields, setFeatureFormDataValues }) => {

    const [modalContent, setModalContent] = useState(null);
    const [showWarningModal, setShowWarningModal] = useState(false);

    const fetchItemInputType = (item) => {
        if (stringUtilities.isNullOrEmpty(item.type)) {
            return <Form.Control size='sm' type="text" value={ formData[item.name] ?? "" } onChange={ e => setFeatureFormDataValues(prevState => ({ ...prevState, [`${ item.name }`]: e.target.value })) } />
        }
        switch (item.type) {
            case "DropDownList":
                return (
                    <Form.Select onChange={ e => setFeatureFormDataValues(prevState => ({ ...prevState, [`${ item.name }`]: e.target.value })) } value={ formData[item.name] ?? "" } size="sm">
                        <option value="">Select a value</option>{ item.values && item.values.length > 0 && item.values.map(value => <option selected={ value === formData[item.name] } key={ value } value={ value }>{ value }</option>) }</Form.Select>
                )
            case "Radio":
                return (
                    <div className="d-flex flex-column">{ item.values && item.values.length > 0 && item.values.map(value => (
                        <Form.Check checked={ value === formData[item.name] } key={ `radio-check-${ value }` } value={ value } onChange={ e => setFeatureFormDataValues(prevState => ({ ...prevState, [`${ item.name }`]: e.target.value })) } type="radio" id={ `radio-check-${ value }` } name="radio-input" label={ value } />
                    )) }
                    </div>
                )
        }
    }
    return (
        <>
            <Form.Group className='mb-3 row'>
                <Form.Label className='form-label fs-sm col-form-label col-md-3' htmlFor="workflow-template">
                    Workflow
                </Form.Label>
                <div className="col-md-9 d-flex align-items-center">
                    <Form.Select size='sm' value={ selectedWorkflow } onChange={ (e) => {
                        const workflowName = workflows.find(item => item.id === e.target.value).name
                        setModalContent({ 
                            message: <><p>Are you sure you want to change the workflow to "{workflowName}"?</p><p>This may lead to data loss.</p></>,
                            param: e.target.value,
                            confirmAction: setSelectedWorkflow
                        })
                        setShowWarningModal(true);
                    } }>
                        <option value="">Select a Workflow</option>
                        {
                            workflows && workflows
                                .map(item => <option key={ item.id } value={ item.id }>{ item.name }</option>)
                        }
                    </Form.Select>
                </div>
            </Form.Group>

            <Form.Group className='mb-3 row'>
                <Form.Label className='form-label fs-sm col-form-label col-md-3' htmlFor="workflow-template">
                    Type
                </Form.Label>
                <div className="col-md-9 d-flex align-items-center">

                    <Form.Select size='sm' value={ selectedFeatureType } onChange={ (e) => {
                        setModalContent({ 
                            message: <><p>Are you sure you want to change the type to "{e.target.value}"?</p><p>This may lead to data loss.</p></>,
                            param: e.target.value,
                            confirmAction: setSelectedFeatureType
                        })
                        setShowWarningModal(true);
                    } }>
                        <option value="">Select a Type</option>
                        {
                            featureTypes && featureTypes
                                .map((item, index) => <option key={ index } value={ item.name }>{ item.name }</option>)
                        }
                    </Form.Select>
                </div>
            </Form.Group>
            {
                featureTypes && featureTypes.length > 0 && featureFormDataFields && featureFormDataFields.map((item, index) => {

                    return (
                        <Form.Group key={ index } className='mb-3 row'>
                            <Form.Label className='form-label fs-sm col-form-label col-md-3' htmlFor="workflow-template">
                                { item.name }
                            </Form.Label>
                            <div className="col-md-9 d-flex align-items-center">
                                { fetchItemInputType(item) }
                            </div>
                        </Form.Group>
                    )
                })
            }


            {
                modalContent &&
                <Modal show={ showWarningModal } onHide={ () => {
                    setModalContent(null);
                    setShowWarningModal(false);
                } } centered>
                    <Modal.Header>
                        <Modal.Title>Warning</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        { modalContent.message }
                    </Modal.Body>
                    <Modal.Footer>
                        <Button size="sm" variant='secondary' onClick={ () => {
                            setModalContent(null);
                            setShowWarningModal(false);
                        } }>Cancel</Button>
                        <Button size="sm" variant='success' onClick={ () => {
                            modalContent.confirmAction(modalContent.param);
                            setShowWarningModal(false);
                        } }>Confirm</Button>
                    </Modal.Footer>
                </Modal>
            }
        </>
    )
}