import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Loading from './common/loading';
import arrayUtilities from '../utilities/arrayUtilities';
import { Table } from 'react-bootstrap';
import stringUtilities from '../utilities/stringUtilities'

import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import useApi from './../utilities/useApi';
import FilterBar from './common/filterBar'
import DeleteButton from './common/deleteButton';
import JobModal from './_jobModal';
import hash from 'object-hash';
import MergeJobModal from './_mergeJobModal';

export default function Jobs(props) {

    const params = useParams();
    const navigate = useNavigate();

    const projectId = params.projectId;
    const organisationId = stringUtilities.isNullOrEmpty(params.organisationId) ? props.organisationId : params.organisationId;

    const { api } = useApi();

    const [isLoading, setIsLoading] = useState(true);
    const [jobs, setJobs] = useState(null);
    const [filter, setFilter] = useState("");
    const [filteredJobs, setFilteredJobs] = useState(null);
    const [projectName, setProjectName] = useState(null);

    const [showJobModal, setShowJobModal] = useState(false);
    const [editingJob, setEditingJob] = useState(null);

    const [mergingJob, setMergingJob] = useState(null);

    const getJobs = function () {
        setIsLoading(true);
        api.getJobs(organisationId, projectId, (data) => {
            setJobs(data);
            setIsLoading(false);
        }, () => {
        });
    }

    const editJob = function (job) {
        setEditingJob(job);
        setShowJobModal(true);
    }

    const deleteJob = function (jobId) {
        api.deleteJob(organisationId, projectId, jobId, () => {
            setJobs(jobs.filter(x => x.id !== jobId));
        }, () => {

        });
    }

    const onJobMerge = function () {

        getJobs();
        setMergingJob(null);
    }

    const onJobModalSave = function () {

        getJobs();
        onJobModalCancel();
    }

    const onJobModalCancel = function () {
        setShowJobModal(false);
        setEditingJob(null);
    }

    useEffect(() => {
        setFilteredJobs(arrayUtilities.isNullOrEmpty(jobs) ? [] : jobs.filter((job) => {
            return stringUtilities.isNullOrEmpty(filter) || job.name.toLowerCase().indexOf(filter.toLowerCase()) > -1 || job.id.toLowerCase().indexOf(filter.toLowerCase()) > -1;
        }));
    }, [filter, jobs]);

    useEffect(() => {
        api.getProject(
            organisationId, projectId,
            data => setProjectName(data.name)
        )
        const timer = setTimeout(() => {
            getJobs();
        }, 500);
        return () => clearTimeout(timer);
    }, []);

    return <>
        <Container className='pt-5 pb-lg-4 mt-5 mb-sm-2'>
            <Row>
                <Col className='mb-5'>
                    { 
                        projectName &&
                            <div className='my-5' style={{display: "grid", gridTemplateColumns: "auto auto", gap:"0.5rem"}}>
                                <h1 className="h2 m-0">{ projectName } - Jobs</h1>

                                <div className='d-flex align-items-center w-100 justify-content-end'>
                                    <Button style={ { marginRight: "20px" } } size='sm' variant='outline-primary' onClick={ () => navigate(`/${ props.organisationId }/projects`) }>
                                        <span className='d-flex align-items-center'><i className='fi-arrow-left me-2'></i>
                                            Back to Projects
                                        </span>
                                    </Button>
                                    <div>
                                    <FilterBar value={ filter } onChange={ setFilter } className="me-4" />
                                    </div>

                                    <Button href='#' size='sm' onClick={ () => setShowJobModal(true) }>
                                        <i className='fi-plus me-2'></i>
                                        Add job
                                    </Button>
                                </div>
                            </div>
                    }                   

                    {isLoading && <Loading />}
                    {!isLoading &&

                        <Table responsive hover>
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th className='text-end'>Points</th>
                                    <th className='text-end'>Lines</th>
                                    <td>&nbsp;</td>
                                </tr>
                            </thead>
                            <tbody>
                                {!arrayUtilities.isNullOrEmpty(filteredJobs) && filteredJobs.map((job, index) => <tr className="align-middle" key={hash(job)} index={index}>
                                    <td className="align-middle">{job.name}</td>
                                    <td className='text-end'>{job.points.toLocaleString()}</td>
                                    <td className='text-end'>{job.lines.toLocaleString()}</td>
                                    <td className="text-end">

                                        {(props.user.roleId === 0 || props.user.roleId === 1) && <>
                                            <Button variant='secondary' size="sm" className='me-2' onClick={() => setMergingJob(job)}>
                                                <i className='fi-folders me-2'></i> Merge
                                            </Button>
                                        </>}

                                        <Button variant='secondary' size="sm" className='me-2' onClick={() => editJob(job)}>
                                            <i className='fi-edit me-2'></i> Edit
                                        </Button>

                                        <DeleteButton size="sm" variant="outline-danger" modalTitle="Delete job" modalContent={<>The job "{job.name}" has {job.points} points and {job.lines} lines. Are you sure you want to delete it?<br /><br />Type the job name below to confirm:</>} confirmationText={job.name} onConfirm={() => deleteJob(job.id)} />
                                    </td>
                                </tr>)}
                            </tbody>
                        </Table>
                    }

                </Col>
            </Row>
        </Container>
        {showJobModal && <JobModal onCancel={onJobModalCancel} onSave={onJobModalSave} job={editingJob} organisationId={organisationId} projectId={projectId} />}
        {mergingJob && mergingJob !== null && <MergeJobModal onCancel={() => setMergingJob(null)} onMerge={onJobMerge} jobs={jobs} job={mergingJob} organisationId={organisationId} projectId={projectId} />}
    </>;
}