import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Loading from './common/loading';
import Modal from 'react-bootstrap/Modal';
import { useEffect, useState } from 'react'
import useApi from './../utilities/useApi';
import arrayUtilities from '../utilities/arrayUtilities';
import { Table } from 'react-bootstrap';
import stringUtilities from '../utilities/stringUtilities'
import { Link, useNavigate } from 'react-router-dom'
import OrganisationSelector from './common/organisationSelector'
import FilterBar from './common/filterBar'
import CopyProjectModal from './_copyProjectModal';

export default function Projects(props) {
    const navigate = useNavigate();
    const { api } = useApi();
    const [isLoading, setIsLoading] = useState(true);
    const [filter, setFilter] = useState("");
    const [projects, setProjects] = useState(null);
    const [filteredProjects, setFilteredProjects] = useState(null);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showCopyModal, setShowCopyModal] = useState(false);
    const [modalProject, setModalProject] = useState(null);

    const getProjects = function (id) {

        setIsLoading(true);
        if (!stringUtilities.isNullOrEmpty(id)) {
            api.getProjects(id, (data) => {
                setProjects(data);
                setIsLoading(false);
            }, () => {
                setProjects(null);
                setIsLoading(false);
            });
        }
        
    };

    useEffect(() => {
        setFilteredProjects(arrayUtilities.isNullOrEmpty(projects) ? [] : projects.filter((project) => {
            return stringUtilities.isNullOrEmpty(filter) || project.name.toLowerCase().indexOf(filter.toLowerCase()) > -1 || project.id.toLowerCase().indexOf(filter.toLowerCase()) > -1;
        }));
    }, [filter, projects]);

    useEffect(() => {
        getProjects(props.organisationId);
    }, [props.organisationId]);

    const onDelete = () => {
        api.deleteProject(
            props.organisationId,
            modalProject.id,
            () => {
                getProjects(props.organisationId);
                setModalProject(null);
                setShowDeleteModal(false);
            },
            () => {}
        )
    }

    const onCopyProject = (newName) => {
        api.copyProject(
            props.organisationId,
            modalProject.id,
            newName,
            () => { 
                getProjects(props.organisationId);
                setShowCopyModal(false);
                setModalProject(null);
            },
            () => { }
        )
    }

    return <Container className='pt-5 pb-lg-4 mt-5 mb-sm-2'>
        <Row>
            <Col className='mb-5'>
                <div className='d-flex justify-content-between my-5'>
                    <h1 className="h2 m-0">Projects</h1>

                    <div className='d-flex justify-content-end align-items-center'>
                        <FilterBar value={filter} onChange={setFilter} className="me-4" />


                        <OrganisationSelector value={props.organisationId ?? props.organisationalUnits[0].id} organisations={props.organisationalUnits} onChange={id => {
                            props.changeOrgId(id);
                            navigate(`/${id}/projects`)
                        }} />

                        <Button onClick={ () => navigate(`/${props.organisationId}/projects/add`) } size='sm' className='ms-2'>
                            <span className='d-flex align-items-center'><i className='fi-plus me-2'></i>
                            Add Project</span>
                        </Button>
                    </div>
                </div>

                {
                    isLoading
                        ? <Loading />
                        : <Table responsive hover className='projects-table'>
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <td>&nbsp;</td>
                                </tr>
                            </thead>
                            <tbody>
                                { !arrayUtilities.isNullOrEmpty(filteredProjects) && filteredProjects.map((project, index) => <tr index={ index } key={ `project-${ project.id }` }>
                                    <td className="align-middle w-100">{ project.name }</td>
                                    <td className="text-end w-100">
                                        <div className='d-flex w-100'>
                                            <Link to={ `/${ props.organisationId }/projects/${ project.id }/jobs` } className='me-2 btn btn-light-primary shadow-sm btn-sm'>
                                                <span className='d-flex align-items-center'><i className='fi-briefcase me-2'></i> Jobs</span>
                                            </Link>

                                            <Link to={ `/${ props.organisationId }/projects/${ project.id }/workflows` } className='me-2 btn btn-light-primary shadow-sm btn-sm'>
                                                <span className='d-flex align-items-center'><i className='fi-route me-2'></i> Workflows</span>
                                            </Link>

                                            <Link to={ `/${ props.organisationId }/projects/${ project.id }/kml-files` } className='me-2 btn btn-light-primary shadow-sm btn-sm'>
                                                <span className='d-flex align-items-center'><i className='fi-code me-2'></i> KML Files</span>
                                            </Link>

                                            <Link to={ `/${ props.organisationId }/projects/${ project.id }/surfaces` } className='me-2 btn btn-light-primary shadow-sm btn-sm'>
                                                <span className='d-flex align-items-center'><i className='fi-route me-2'></i> Surfaces</span>
                                            </Link>


                                            <Link to={ `/${ props.organisationId }/projects/${ project.id }/map` } className='me-2 btn btn-outline-primary shadow-sm btn-sm'>
                                                <span className='d-flex align-items-center'><i className='fi-map-pin me-2'></i> Map</span>
                                            </Link>

                                            <Button variant='secondary' size="sm" className='me-2 btn btn-light-primary shadow-sm btn-sm' onClick={ () => {
                                                setModalProject(project);
                                                setShowCopyModal(true);
                                            } }>
                                                <span className='d-flex align-items-center'><i className='fi-folders me-2'></i> Copy</span>
                                            </Button>

                                            <Link to={ `/${ props.organisationId }/projects/${ project.id }` } variant='secondary' size="sm" className='me-2 btn btn-light-primary shadow-sm btn-sm'>
                                                <span className='d-flex align-items-center'><i className='fi-edit me-2'></i> Edit</span>
                                            </Link>

                                            <Button variant='outline-danger' size="sm" onClick={ () => {
                                                setModalProject(project);
                                                setShowDeleteModal(true);
                                            } }>
                                                <span className='d-flex align-items-center'><i className='fi-trash me-2'></i> Delete</span>
                                            </Button>
                                        </div>
                                    </td>
                                </tr>) }
                            </tbody>
                        </Table>
                }
            </Col>
        </Row>

        {
            modalProject && 
            <Modal show={showDeleteModal} onHide={() => {
                setModalProject(null);
                setShowDeleteModal(false);
            }} centered>
                <Modal.Header>
                    <Modal.Title>Are you sure you want to delete the Project "{modalProject.name}"?</Modal.Title>
                </Modal.Header>
                <Modal.Footer>
                    <Button size="sm" variant='secondary' onClick={() => {
                        setModalProject(null);
                        setShowDeleteModal(false);
                    }}>Cancel</Button>
                    <Button size="sm" variant="primary" onClick={onDelete}>Delete</Button>
                </Modal.Footer>
            </Modal>
        }

        {
            showCopyModal && modalProject && 
            <CopyProjectModal onCopy={newName => onCopyProject(newName)} name={modalProject.name} onCancel={() => {
                setModalProject(null);
                setShowCopyModal(false);
            }} />
        }
    </Container>;
}