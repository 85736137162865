import { useState, useEffect } from "react";
import { useParams, useNavigate } from 'react-router-dom';
import useApi from "../utilities/useApi";
import stringUtilities from "../utilities/stringUtilities";
import { ProjectAddEdit } from "./common/formsCreateEdit/projectAddEdit";

export default function AddProject() {
    const navigate = useNavigate();
    const { organisationId } = useParams();
    const { api } = useApi();
    const [name, setName] = useState("");
    const [syncDataDays, setSyncDataDays] = useState("0");
    const [canSave, setCanSave] = useState(false);
    const [center, setCenter] = useState({
        lat: 0,
        lng: 0
    });
    const [mapOutputWidth, setMapOutputWidth] = useState("0");
    const [mapOutputHeight, setMapOutputHeight] = useState("0");
    
    const handleSubmit = () => {
        const configuration = {
            mapConfiguration: {
                mapMarkersAsDots: true,
            },
            mapOutputWidth: mapOutputWidth, 
            mapOutputHeight: mapOutputHeight
        }
        api.addProject(
            organisationId,
            name,
            parseFloat(center.lng), parseFloat(center.lat), parseInt(syncDataDays), configuration,
            () => navigate(`/${ organisationId }/projects`),
            () => { }
        )
    }

    useEffect(() => {
        setCanSave(!stringUtilities.isNullOrEmpty(name) && !stringUtilities.isNullOrEmpty(syncDataDays))
    }, [name, syncDataDays])

    return (
        <ProjectAddEdit
            title="Add Project"
            name={ name }
            setName={ setName }
            syncDataDays={ syncDataDays }
            setSyncDataDays={ setSyncDataDays }
            center={ center }
            setCenter={ setCenter }
            mapOutputWidth={mapOutputWidth}
            setMapOutputWidth={setMapOutputWidth}
            mapOutputHeight={mapOutputHeight}
            setMapOutputHeight={setMapOutputHeight}
            canSave={ canSave }
            handleSubmit={ handleSubmit }
            handleCancel={ () => navigate(`/${ organisationId }/projects`) }
        />
    )
}