import { useState, useEffect, useRef } from "react";
import stringUtilities from "../utilities/stringUtilities";
import { WorkflowAddEdit } from "../components/common/formsCreateEdit/workflowAddEdit";
import useApi from "../utilities/useApi";
import { useParams, useNavigate } from 'react-router-dom';

export default function AddWorkflow() {
    const { api } = useApi();
    const navigate = useNavigate();
    const { organisationId, projectId } = useParams();
    const [name, setName] = useState("");

    const [isComposite, setIsComposite] = useState(false);
    const [workflowTemplateId, setWorkflowTemplateId] = useState(null);
    const [workflowTemplates, setWorkflowTemplates] = useState([]);
    const [workflowType, setWorkflowType] = useState("9");
    const [hue, setHue] = useState(0);
    const [rgbColor, setRgbColor] = useState(null);
    const [order, setOrder] = useState("0");
    const [configuration, setConfiguration] = useState(null);
    const [canSave, setCanSave] = useState(false);
    const [canSaveTemplate, setCanSaveTemplate] = useState(false);

    const [isValidJson, setIsValidJson] = useState(true);

    const changeConfigurationTimeout = useRef();
    const handleConfigurationChange = function (value) {
        if (stringUtilities.isNullOrEmpty(value)) {
            setConfiguration(null);
            setIsValidJson(true)
            return
        }
        setConfiguration(value);
        stringUtilities.validateJsonWithDebouncer(value, changeConfigurationTimeout, setIsValidJson)
    }; 

    const initialiseWorkflowTypeValue = (type) => {
        return (type !== 9 && type !== 10) ? 9 : type
    }

    const handleSubmit = () => {
        api.addWorkflow(
            organisationId, projectId,
            name, parseInt(order), configuration, parseInt(workflowType), parseInt(hue), rgbColor, workflowTemplateId, isComposite,
            () => navigate(`/${ organisationId }/projects/${ projectId }/workflows`),
            () => { }
        )
    }

    const resetTemplateValues = () => {
        setWorkflowType("9");
        setHue(0);
        setRgbColor("#000000");
        setOrder("0");
        setConfiguration(null);
    }

    useEffect(() => {
        api.getWorkflowTemplates(
            organisationId,
            data => setWorkflowTemplates(data),
            () => { }
        )
    }, [])

    useEffect(() => {
        setCanSave(!stringUtilities.isNullOrEmpty(name) && !stringUtilities.isNullOrEmpty(order) && !stringUtilities.isNullOrEmpty(workflowType) && hue !== undefined && isValidJson)
        setCanSaveTemplate(!stringUtilities.isNullOrEmpty(order) && !stringUtilities.isNullOrEmpty(workflowType) && hue !== undefined && isValidJson)
    }, [name, order, workflowType, hue, isValidJson])

    const handleWorkflowTemplateChange = (templateId) => {
        if (!stringUtilities.isNullOrEmpty(templateId)) {
            setWorkflowTemplateId(templateId);
            const selectedTemplate = workflowTemplates.find(item => item.id === templateId);
            setWorkflowType(String(initialiseWorkflowTypeValue(selectedTemplate.workflowTypeId)));
            setHue(selectedTemplate.hue);
            setRgbColor(stringUtilities.checkColorHash(selectedTemplate.color));
            setOrder(String(selectedTemplate.orderBy));
            setConfiguration(selectedTemplate.configuration);
        } else {
            setWorkflowTemplateId(null);
            resetTemplateValues();
        }
    }

    const onSaveTemplate = (newTemplateName) => {
        api.addWorkflowTemplate(
            organisationId,
            newTemplateName, parseInt(order), configuration, parseInt(workflowType), parseInt(hue), rgbColor,
            () => navigate(`/${ organisationId }/projects/${projectId}/workflows`),
            () => {},
        )
    }

    return (
        <>
            <WorkflowAddEdit
                title="Add workflow"
                name={ name }
                setName={ setName }
                isComposite={ isComposite }
                setIsComposite={ setIsComposite }
                workflowTemplates={ workflowTemplates }
                workflowTemplateId={ workflowTemplateId }
                handleWorkflowTemplateChange={ templateId => handleWorkflowTemplateChange(templateId) }
                workflowType={ workflowType }
                setWorkflowType={ setWorkflowType }
                hue={ hue }
                setHue={ setHue }
                rgbColor={ rgbColor }
                setRgbColor={ setRgbColor }
                order={ order }
                setOrder={ setOrder }
                configuration={ configuration }
                handleConfigurationChange={ value => handleConfigurationChange(value) }
                canSave={ canSave }
                canSaveTemplate={ canSaveTemplate }
                handleSubmit={ handleSubmit }
                handleCancel={ () => navigate(`/${ organisationId }/projects/${ projectId }/workflows`) }
                handleSaveTemplate={ newTemplateName => onSaveTemplate(newTemplateName)}
                isValidJson={isValidJson}
            />
        </>
    )
}