import stringUtilities from './stringUtilities';
import postal from 'postal';

export default function useApi() {

    const IsProduction = window.top.location.host.toLowerCase() === "app.terraflow.ca" || window.top.location.host.toLowerCase() === "prd-terraflow-app.azurewebsites.net";
    const ApiBaseUrl = IsProduction ? "https://api.terraflow.ca" : "https://stg-terraflow-api.azurewebsites.net";
    const LoginBaseUrl = IsProduction ? "https://auth.terraflow.ca/account/log-in?returnUrl=" : "https://staging.terraflow.ca/account/log-in?returnUrl=";
    const LogoutBaseUrl = IsProduction ? "https://auth.terraflow.ca/account/logout?returnUrl=" : "https://staging.terraflow.ca/account/logout?returnUrl=";

    const getToken = function () {
        let token = localStorage.getItem("token");
        if (!stringUtilities.isNullOrEmpty(token)) {
            return token;
        }

        redirectToLogin();
    };

    const getReturnUrl = function () {
        let returnUrl = window.top.location.href;
        if (returnUrl.indexOf("#") > -1) {
            returnUrl = returnUrl.substring(0, returnUrl.indexOf("#"));
        }

        return returnUrl;
    };

    const redirectToLogin = function () {

        window.top.location = `${LoginBaseUrl}${getReturnUrl()}`;
    }

    const redirectToLogout = function () {

        window.top.location = `${LogoutBaseUrl}${getReturnUrl()}`
    }

    const handleErrors = function (response) {

        if (response.status === 401) {
            throw Error(`API Error (${response.statusText}) at ${response.url}`);
        }

        return response;
    };

    const fetchGet = function (url, onSuccess, onError, suppressErrorModal) {

        fetch(ApiBaseUrl + url, {
            method: 'GET',
            headers: { 'Authorization': 'Bearer ' + getToken() }
        })
            .then(response => response.json())
            .then(data => {
                if (data.isError) {
                    return onRejected(new Error(data.errorMessage), onError, suppressErrorModal);
                }
                return onFulfilled(data, onSuccess);
            })
            .catch((error) => onRejected(error, onError, suppressErrorModal));
    };

    const fetchDelete = function (url, onSuccess, onError, suppressErrorModal, queryParam, queryParamValue) {
        fetchDeleteWithBody(url, null, onSuccess, onError, suppressErrorModal, queryParam, queryParamValue);
    };

    const fetchDeleteWithBody = function (url, body, onSuccess, onError, suppressErrorModal, queryParam, queryParamValue) {

        fetch(ApiBaseUrl + url + `?${queryParam}=${encodeURIComponent(queryParamValue)}`, {
            method: 'DELETE',
            headers: {
                "Content-Type": "application/json",
                'Authorization': 'Bearer ' + getToken()
            },
            body: JSON.stringify(body),
            redirect: 'follow'
        })
            .then(handleErrors)
            .then(data => onFulfilled(data, onSuccess))
            .catch((error) => onRejected(error, onError, suppressErrorModal));
    };

    const fetchPatch = function (url, body, onSuccess, onError, suppressErrorModal) {

        let requestOptions = {
            method: 'PATCH',
            headers: {
                "Content-Type": "application/json",
                'Authorization': 'Bearer ' + getToken(),
            },
            body: JSON.stringify(body),
            redirect: 'follow'
        };

        if (onSuccess) {
            fetch(ApiBaseUrl + url, requestOptions)
                .then(handleErrors)
                .then(response => response.json())
                .then(data => onFulfilled(data, onSuccess))
                .catch((error) => onRejected(error, onError, suppressErrorModal));
        } else {
            fetch(ApiBaseUrl + url, requestOptions)
                .catch((error) => onRejected(error, onError, suppressErrorModal));
        }
    };

    const fetchPost = function (url, body, signal, onSuccess, onError, suppressErrorModal) {

        let requestOptions = {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                'Authorization': 'Bearer ' + getToken(),
            },
            body: JSON.stringify(body),
            redirect: 'follow'
        };

        if (signal !== null) {
            requestOptions.signal = signal
        }

        if (onSuccess) {
            fetch(ApiBaseUrl + url, requestOptions)
                .then(handleErrors)
                .then(response => {
                    const contentType = response.headers.get("content-type");
                    if (contentType && contentType.indexOf("application/json") !== -1) {
                        return response.json();
                    } else {
                        return response.text();
                    }
                })
                .then(data => {
                    if (data.isError) {
                        return onRejected(new Error(data.errorMessage), onError, suppressErrorModal);
                    }
                    return onFulfilled(data, onSuccess);
                })
                .catch((error) => onRejected(error, onError, suppressErrorModal));
        } else {
            fetch(ApiBaseUrl + url, requestOptions)
                .catch((error) => onRejected(error, onError, suppressErrorModal));
        }
    };


    const fetchPostForBlob = function (url, body, onSuccess, onError, suppressErrorModal) {

        var filename;

        let requestOptions = {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                'Authorization': 'Bearer ' + getToken(),
            },
            body: JSON.stringify(body),
            redirect: 'follow'
        };

        if (onSuccess) {
            fetch(ApiBaseUrl + url, requestOptions)
                .then(handleErrors)
                .then(function (response) {
                    let contentDisposition = response.headers.get("content-disposition");

                    let contentDispositionParts = contentDisposition.split(";");
                    let filenameElement = contentDispositionParts.find(x => x.indexOf("filename=") > 0);
    
                    if (filenameElement) {
                        filename = filenameElement.split("=")[1];
                    }

                    return response.blob();
                }).then(function (blob) {
                
                    if (onSuccess) {
                        onSuccess(blob, filename);
                    }
                }).catch((error) => onRejected(error, onError, suppressErrorModal));
        } else {
            fetch(ApiBaseUrl + url, requestOptions)
                .catch((error) => onRejected(error, onError, suppressErrorModal));
        }
    };

    const fetchPostForFileUpload = function (url, formData, signal, onSuccess, onError, suppressErrorModal) {

        let requestOptions = {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + getToken(),
            },
            body: formData,
            redirect: 'follow'
        };

        if (signal !== null) {
            requestOptions.signal = signal
        }

        if (onSuccess) {
            fetch(ApiBaseUrl + url, requestOptions)
                .then(handleErrors)
                .then(response => {
                    const contentType = response.headers.get("content-type");
                    if (contentType && contentType.indexOf("application/json") !== -1) {
                        return response.json();
                    } else {
                        return response.text();
                    }
                })
                .then(data => {
                    if (data.isError) {
                        return onRejected(new Error(data.errorMessage), onError, suppressErrorModal);
                    }
                    return onFulfilled(data, onSuccess);
                })
                .catch((error) => onRejected(error, onError, suppressErrorModal));
        } else {
            fetch(ApiBaseUrl + url, requestOptions)
                .catch((error) => onRejected(error, onError, suppressErrorModal));
        }
    };

    const fetchPut = function (url, body, onSuccess, onError, suppressErrorModal) {
        
        let requestOptions = {
            method: 'PUT',
            headers: {
                "Content-Type": "application/json",
                'Authorization': 'Bearer ' + getToken(),
            },
            body: JSON.stringify(body),
            redirect: 'follow'
        };

        if (onSuccess) {
            fetch(ApiBaseUrl + url, requestOptions)
                .then(handleErrors)
                .then(response => response.json())
                .then(data => onFulfilled(data, onSuccess))
                .catch((error) => onRejected(error, onError, suppressErrorModal));
        } else {
            fetch(ApiBaseUrl + url, requestOptions)
                .catch((error) => onRejected(error, onError, suppressErrorModal));
        }
    };

    const fetchPutForFileUpload = function (url, file, onSuccess, onError, suppressErrorModal) {

        let requestOptions = {
            method: 'PUT',
            headers: {
                'Authorization': 'Bearer ' + getToken(),
            },
            body: file,
            redirect: 'follow'
        };

        if (onSuccess) {
            fetch(ApiBaseUrl + url, requestOptions)
                .then(handleErrors)
                .then(response => response.json())
                .then(data => onFulfilled(data, onSuccess))
                .catch((error) => onRejected(error, onError, suppressErrorModal));
        } else {
            fetch(ApiBaseUrl + url, requestOptions)
                .catch((error) => onRejected(error, onError, suppressErrorModal));
        }
    };

    const onFulfilled = function (data, onSuccess) {
        if (onSuccess) {
            onSuccess(data);
        }
    };

    const onRejected = function (error, onError, suppressErrorModal) {

        if (!suppressErrorModal) {
            postal.publish({
                channel: "error",
                topic: "thrown",
                data: {
                    error
                }
            });
        }

        if (onError) {
            onError(error);
        }
    };

    return {
        api: {
            setToken(token) {
                localStorage.setItem("token", token);
            },

            signout() {
                localStorage.setItem("token", "");
                redirectToLogout();
            },

            /* USERS */
            getUser(onSuccess, onError) {
                fetchGet('/api/user', onSuccess, () => {
                    if (onError) {
                        onError();
                    }

                    redirectToLogin();
                }, true);
            },

            userAcceptTerms(onSuccess, onError) {
                fetchPost("/api/user/accept-terms", null, null, onSuccess, onError);
            },

            getUsers(organisationId, includeDeleted, onSuccess, onError) {
                fetchGet(`/api/${organisationId}/users${includeDeleted ? "?includeDeleted=true" : ""}`, onSuccess, onError);
            },

            deleteUser(organisationId, userId, onSuccess, onError) {
                fetchDelete(`/api/${organisationId}/users/${userId}`, onSuccess, onError);
            },

            patchUser(organisationId, userId, disabled, deleted, onSuccess, onError) {
                fetchPatch(`/api/${organisationId}/users/${userId}`, { disabled, deleted }, onSuccess, onError);
            },

            addUser(organisationId, firstName, lastName, emailAddress, roleId, onSuccess, onError) {
                fetchPost(`/api/${organisationId}/users`, { firstName, lastName, emailAddress, roleId }, null, onSuccess, onError);
            },

            /* ORGANISATIONAL UNITS */
            getOrganisationalUnits(onSuccess, onError) {
                fetchGet('/api/organisational-units', onSuccess, onError);
            },

            updateOrganisationalUnit(updateOrg, name, fieldUserLimit, officeUserLimit, onSuccess, onError) {
                fetchPut(`/api/organisational-units/${updateOrg.id}`, { name, disabled: updateOrg.disabled, tasks: updateOrg.tasks, setSetOffsetColorWorkflow: updateOrg.setSetOffsetColorWorkflow, fieldUserLimit, officeUserLimit}, onSuccess, onError);
            },

            getOrganisationalUnit(organisationalUnitId, onSuccess, onError) {
                fetchGet(`/api/organisational-units/${organisationalUnitId}`, onSuccess, onError);
            },

            /*  Api KEYS */ 
            getApiKeys(organisationId, onSuccess, onError) {
                fetchGet(`/api/${organisationId}/api-keys`, onSuccess, onError, true);
            },

            addApiKey(organisationId, name, role, expiresAt, onSuccess, onError) {
                fetchPost(`/api/${organisationId}/api-keys`, { name, role, expiresAt, }, null, onSuccess, onError);
            },

            revokeApiKey(organisationId, apiKeyId, onSuccess, onError) {
                fetchPatch(`/api/${organisationId}/api-keys/${apiKeyId}/revoke`, null, onSuccess, onError, true)
            },

            updateApiKey(organisationId, apiKeyId, name, role, expiresAt, isActive, onSuccess, onError) {
                fetchPut(`/api/${organisationId}/api-keys/${apiKeyId}`, { name, role, expiresAt, isActive }, onSuccess, onError);
            },

            /* PROJECTS */
            getProjects(organisationId, onSuccess, onError) {
                fetchGet(`/api/${organisationId}/projects`, onSuccess, onError, true);
            },

            addProject(organisationId, name, longitude, latitude, syncDataDays, configuration, onSuccess, onError) {
                fetchPost(`/api/${organisationId}/projects`, { name, longitude, latitude, syncDataDays, configuration }, null, onSuccess, onError);
            },

            editProject(organisationId, projectId, name, longitude, latitude, syncDataDays, configuration, onSuccess, onError) {
                fetchPut(`/api/${organisationId}/projects/${projectId}`, { name, longitude, latitude, syncDataDays, configuration }, onSuccess, onError);
            },

            deleteProject(organisationId, projectId, onSuccess, onError) {
                fetchDelete(`/api/${organisationId}/projects/${projectId}`, onSuccess, onError);
            },

            copyProject(organisationId, projectId, name, onSuccess, onError) {
                fetchPost(`/api/${organisationId}/projects/${projectId}/copy`, { name }, null, onSuccess, onError);
            },

            /* JOBS */

            addJob(organisationId, projectId, name, onSuccess, onError) {
                fetchPost(`/api/${organisationId}/projects/${projectId}/jobs`, { name }, null, onSuccess, onError);
            },

            editJob(organisationId, projectId, jobId, name, onSuccess, onError) {
                fetchPut(`/api/${organisationId}/projects/${projectId}/jobs/${jobId}`, { name }, onSuccess, onError);
            },

            mergeJob(organisationId, projectId, jobId, targetJobId, onSuccess, onError) {
                fetchPost(`/api/${organisationId}/projects/${projectId}/jobs/${jobId}`, { targetJobId }, null, onSuccess, onError);
            },

            getJobs(organisationId, projectId, onSuccess, onError) {
                fetchGet(`/api/${organisationId}/projects/${projectId}/jobs`, onSuccess, onError);
            },

            getProject(organisationId, projectId, onSuccess, onError) {
                fetchGet(`/api/${organisationId}/projects/${projectId}`, onSuccess, onError);
            },

            deleteJob(organisationId, projectId, jobId, onSuccess, onError) {
                fetchDelete(`/api/${organisationId}/projects/${projectId}/jobs/${jobId}`, onSuccess, onError);
            },

            /* SKETCHES */
            getSketches(organisationId, projectId, onSuccess, onError) {
                fetchGet(`/api/${organisationId}/projects/${projectId}/sketches`, onSuccess, onError);
            },

            getSketch(organisationId, projectId, sketchId, onSuccess, onError) {
                fetchGet(`/api/${organisationId}/projects/${projectId}/sketches/${sketchId}`, onSuccess, onError);
            },

            addSketch(organisationId, projectId, name, onSuccess, onError) {
                fetchPost(`/api/${organisationId}/projects/${projectId}/sketches`, { name }, null, onSuccess, onError, true);
            },

            saveSketch(organisationId, projectId, sketchId, sketch, onSuccess, onError) {
                fetchPut(`/api/${organisationId}/projects/${projectId}/sketches/${sketchId}`, sketch, onSuccess, onError, true);
            },

            deleteSketch(organisationId, projectId, sketchId, onSuccess, onError) {
                fetchDelete(`/api/${organisationId}/projects/${projectId}/sketches/${sketchId}`, onSuccess, onError);
            },

            /* WORKFLOWS */
            getWorkflows(organisationId, projectId, onSuccess, onError) {
                fetchGet(`/api/${organisationId}/projects/${projectId}/workflows`, onSuccess, onError);
            },

            getWorkflow(organisationId, projectId, workflowId, onSuccess, onError) {
                fetchGet(`/api/${organisationId}/projects/${projectId}/workflows/${workflowId}`, onSuccess, onError);
            },

            getWorkflowTemplates(organisationId, onSuccess, onError) {
                fetchGet(`/api/${organisationId}/workflow-templates`, onSuccess, onError);
            },

            getWorkflowTemplate(organisationId, workflowTemplateId, onSuccess, onError) {
                fetchGet(`/api/${organisationId}/workflow-templates/${workflowTemplateId}`, onSuccess, onError);
            },

            addWorkflow(organisationId, projectId, name, orderBy, configuration, workflowType, hue, colour, workflowTemplateId, isComposite, onSuccess, onError) {
                fetchPost(`/api/${organisationId}/projects/${projectId}/workflows`, { name, orderBy, configuration, workflowType, hue, colour, workflowTemplateId, isComposite }, null, onSuccess, onError, true);
            },

            editWorkflow(organisationId, projectId, workflowId, name, orderBy, configuration, workflowType, hue, colour, workflowTemplateId, isComposite, onSuccess, onError) {
                fetchPut(`/api/${organisationId}/projects/${projectId}/workflows/${workflowId}`, { name, orderBy, configuration, workflowType, hue, colour, workflowTemplateId, isComposite }, onSuccess, onError, true);
            },

            deleteWorkflow(organisationId, projectId, workflowId, onSuccess, onError) {
                fetchDelete(`/api/${organisationId}/projects/${projectId}/workflows/${workflowId}`, onSuccess, onError);
            },

            addWorkflowTemplate(organisationId, name, orderBy, configuration, workflowTypeId, hue, color, onSuccess, onError) {
                fetchPost(`/api/${organisationId}/workflow-templates`, { name, orderBy, configuration, workflowTypeId, hue, color }, null, onSuccess, onError, true);
            },

            editWorkflowTemplate(organisationId, workflowTemplateId, name, orderBy, configuration, workflowType, hue, color, onSuccess, onError) {
                fetchPut(`/api/${organisationId}/workflow-templates/${workflowTemplateId}`, { name, orderBy, configuration, workflowType, hue, color }, onSuccess, onError, true);
            },

            /* KML Files */
            getKmlFiles(organisationId, projectId, onSuccess, onError) {
                fetchGet(`/api/${organisationId}/projects/${projectId}/kml-files`, onSuccess, onError)
            },

            getKmlFile(organisationId, projectId, kmlFileId, onSuccess, onError) {
                fetchGet(`/api/${organisationId}/projects/${projectId}/kml-files/${kmlFileId}`, onSuccess, onError)
            },

            addKmlFile(organisationId, projectId, file, onSuccess, onError) {
                fetchPostForFileUpload(`/api/${organisationId}/projects/${projectId}/kml-files`, file, null, onSuccess, onError, true)
            },

            deleteKmlFile(organisationId, projectId, kmlFileId, onSuccess, onError) {
                fetchDelete(`/api/${organisationId}/projects/${projectId}/kml-files`, onSuccess, onError, true, "kmlFileId", kmlFileId);
            },

             /* Surfaces */
             getSurfaces(organisationId, projectId, onSuccess, onError) {
                fetchGet(`/api/${organisationId}/projects/${projectId}/surfaces`, onSuccess, onError)
            },

            getSurface(organisationId, projectId, surfaceId, onSuccess, onError) {
                fetchGet(`/api/${organisationId}/projects/${projectId}/surfaces/${surfaceId}`, onSuccess, onError)
            },

            addSurface(organisationId, projectId, file, onSuccess, onError) {
                fetchPostForFileUpload(`/api/${organisationId}/projects/${projectId}/surfaces`, file, null, onSuccess, onError, true)
            },

            editSurface(organisationId, projectId, surfaceId, file, onSuccess, onError) {
                fetchPutForFileUpload(`/api/${organisationId}/projects/${projectId}/surfaces/${surfaceId}`, file, onSuccess, onError, true)
            },

            deleteSurface(organisationId, projectId, surfaceId, onSuccess, onError) {
                fetchDelete(`/api/${organisationId}/projects/${projectId}/surfaces`, onSuccess, onError, true, "surfaceId", surfaceId);
            },

            /* DATA */
            getData(organisationId, projectId, jobIds, userIds, workflowIds, topLat, bottomLat, leftLng, rightLng, dateFrom, dateTo, timeOffset, exportFormat, srid, includePhotos, signal, onSuccess, onError) {

                let payload = { jobIds, userIds, workflowIds, topLat, bottomLat, leftLng, rightLng, dateFrom, dateTo, timeOffset, exportFormat, srid, includePhotos };

                if (exportFormat === 0) {
                    fetchPost(`/api/${organisationId}/projects/${projectId}/data`, payload, signal, onSuccess, onError, true);
                } else {
                    fetchPostForBlob(`/api/${organisationId}/projects/${projectId}/data`, payload, onSuccess, onError, false);
                }
            },

            getPoint(organisationId, projectId, pointId, onSuccess, onError) {
                fetchGet(`/api/${organisationId}/projects/${projectId}/points/${pointId}`, onSuccess, onError, true)
            },

            deletePoint(organisationId, projectId, pointId, deleteMode, onSuccess, onError) {
                fetchDeleteWithBody(`/api/${organisationId}/projects/${projectId}/points/${pointId}`, { deleteMode }, onSuccess, onError, true)
            },

            updatePointAttributes(organisationId, projectId, pointId, workflowId, formData, gpsData, sensorData, photos, attachments, onSuccess, onError) {
                const jsonString = JSON.stringify(formData)
                const body = { workflowId, formData: jsonString, gpsData: null, sensorData: null, photos: null, attachments: null }
                fetchPatch(`/api/${organisationId}/projects/${projectId}/points/${pointId}`, body, onSuccess, onError, false)
            },

            /* STAMPS */
            getStamps(organisationId, onSuccess, onError) {
                fetchGet(`/api/${organisationId}/stamps`, onSuccess, onError);
            },

            addStamp(organisationId, name, svg, tagIds, onSuccess, onError) {
                const payload = new FormData();
                payload.append('Name', name);
                payload.append('Stamp', svg);
                payload.append('TagIds', tagIds);

                const xhr = new XMLHttpRequest();

                xhr.upload.onerror = (e) => {
                    if (onError) {
                        onError(e);
                    }
                };

                xhr.onreadystatechange = function () {

                    if (xhr.readyState === 4) {
                        if (xhr.status === 200) {
                            if (onSuccess) {

                                let result = JSON.parse(xhr.response);
                                onSuccess(result);
                            }
                        } else {
                            if (onError) {
                                onError();
                            }
                        }
                    }
                };

                xhr.open('POST', ApiBaseUrl + `/api/${organisationId}/stamps`, true);
                xhr.setRequestHeader('Authorization', 'Bearer ' + getToken());
                xhr.send(payload);
            },


            editStamp(organisationId, stampId, name, svg, tagIds, onSuccess, onError) {
                const payload = new FormData();
                payload.append('Name', name);
                payload.append('Stamp', svg);
                payload.append('TagIds', tagIds);

                const xhr = new XMLHttpRequest();

                xhr.upload.onerror = (e) => {
                    if (onError) {
                        onError(e);
                    }
                };

                xhr.onreadystatechange = function () {

                    if (xhr.readyState === 4) {
                        if (xhr.status === 200) {
                            if (onSuccess) {

                                let result = JSON.parse(xhr.response);
                                onSuccess(result);
                            }
                        } else {
                            if (onError) {
                                onError();
                            }
                        }
                    }
                };

                xhr.open('PUT', ApiBaseUrl + `/api/${organisationId}/stamps/${stampId}`, true);
                xhr.setRequestHeader('Authorization', 'Bearer ' + getToken());
                xhr.send(payload);
            },

            deleteStamp(organisationId, stampId, onSuccess, onError) {
                fetchDelete(`/api/${organisationId}/stamps/${stampId}`, onSuccess, onError)
            },

            /* TAGS */
            getTags(organisationId, onSuccess, onError) {
                fetchGet(`/api/${organisationId}/tags`, onSuccess, onError);
            },

            addTag(organisationId, name, onSuccess, onError) {
                fetchPost(`/api/${organisationId}/tags`, { name }, null, onSuccess, onError);
            },

            /* REPORTS */
            userActivityReport(organisationId, startDate, endDate, onSuccess, onError) {
                fetchPost(`/api/${organisationId}/reports/user-activity`, { startDate, endDate }, null, onSuccess, onError, true);
            },

            /* SETTINGS */
            getDeviceManagementSettings(organisationId, onSuccess, onError) {
                fetchGet(`/api/${organisationId}/settings/device-management`, onSuccess, onError, true);
            },

            saveDeviceManagementSettings(organisationId, saveAndMoreMode, autoSync, autoSyncFrequency, bluetoothWindow, audibleToneOnPointCollection, locationSource, locationTranslation, accuracyLimitEnabled, accuracyLimitCentimetres, onSuccess, onError) {
                fetchPut(`/api/${organisationId}/settings/device-management`, {
                    saveAndMoreMode, autoSync, autoSyncFrequency, bluetoothWindow, audibleToneOnPointCollection, locationSource, locationTranslation, accuracyLimitEnabled, accuracyLimitCentimetres
                }, onSuccess, onError);
            }
        }
    }
}