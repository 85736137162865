import { Col, Container, Row, Button, Form } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import useApi from '../utilities/useApi';
import { useState, useRef } from 'react';

export default function AddKmlFile() {
    const navigate = useNavigate();
    const {organisationId, projectId} = useParams();
    const {api} = useApi();
    const [file, setFile] = useState(null);
    const [filename, setFilename] = useState("");
    const [canSave, setCanSave] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const inputRef = useRef();
    const handleChange = (e) => {
        if (inputRef.current && inputRef.current.files[0]) {
            const inputFile = inputRef.current.files[0];
            if (inputFile.name.toLowerCase().endsWith("kmz") || inputFile.name.toLowerCase().endsWith("kml")) {
                setFilename(inputRef.current.files[0].name)
                if (inputFile) {
                    const formData = new FormData();
                    formData.append('File', inputFile);
                    setFile(formData);
                    setErrorMessage(null);
                    setCanSave(true);
                }
            } else setErrorMessage("Invalid file extension. File should be of type .kml or .kmz")
        }
    }
    const handleSubmit = () => {
        api.addKmlFile(
            organisationId, projectId,
            file,
            () => navigate(`/${ organisationId }/projects/${projectId}/kml-files`),
            (data) => console.log(data),
        )
    }

    return (
        <Container className='pt-5 pb-lg-4 mt-5 mb-sm-2'>
            <Row>
                <Col className='mb-5'>
                    <div className='d-flex justify-content-between my-5'>
                        <h1 className="h2 m-0">Add KML File</h1>
                    </div>
                    <div>
                        <Form.Group className='mb-3 row'>
                            <Form.Label className='form-label fs-sm col-form-label col-md-3' htmlFor="kml-fileUpload">
                                File
                            </Form.Label>
                            <div className="col-md-9 d-flex align-items-center file-input">
                                <input className="file-upload-button" ref={inputRef} onChange={e => handleChange(e)} type="file" id="kml-fileUpload" name="kml-file" accept='.kml, .kmz' />
                                <div className="d-flex align-items-center">
                                    <label for="kml-fileUpload"><i className='fi-cloud-upload me-2'></i>Upload File</label>
                                    <p className="m-0">{filename}</p>
                                    {errorMessage && <p className='m-0 text-danger'>{errorMessage}</p>}
                                </div>
                            </div>
                        </Form.Group>
                        <Form.Group className='mt-4 row justify-content-end'>
                            <div className="col-md-9">
                                <Button onClick={ () => navigate(`/${ organisationId }/projects/${projectId}/kml-files`) } variant='outline-primary' size="sm">
                                    Cancel
                                </Button>
                                <Button disabled={!canSave} onClick={ handleSubmit } size='sm' className='ms-2'>
                                    Submit
                                </Button>
                            </div>
                        </Form.Group>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}