import { useEffect, useState } from 'react';
import { Col, Container, Row, Table, Button } from 'react-bootstrap';
import useApi from '../utilities/useApi';
import arrayUtilities from '../utilities/arrayUtilities';
import stringUtilities from '../utilities/stringUtilities';
import FilterBar from './common/filterBar';
import Loading from './common/loading';
import OrganisationModal from './_organisationModal';
import Dropdown from 'react-bootstrap/Dropdown';
import { useNavigate } from 'react-router-dom';

export default function Organisations(props) {

    const { api } = useApi();
    const navigate = useNavigate();
    const [filter, setFilter] = useState("");
    const [filteredOrganisations, setFilteredOrganisations] = useState(null);
    const [user, setUser] = useState(null);
    const [organisationalUnits, setOrganisationalUnits] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [showOrganisationModal, setShowOrganisationModal] = useState(false);
    const [updateOrg, setUpdateOrg] = useState(null);

    const refreshUser = function () {
        api.getUser((data) => {
            setUser(data);
            setIsLoading(false);

            api.getOrganisationalUnits((data) => setOrganisationalUnits(data))
        }, () => {
            setUser(null);
            setIsLoading(false);
        });
    }

    useEffect(() => {
        refreshUser();
    }, [])

    
    useEffect(() => {
        setFilteredOrganisations(arrayUtilities.isNullOrEmpty(organisationalUnits) ? [] : organisationalUnits.filter((o) => {
            return stringUtilities.isNullOrEmpty(filter) ||
                o.name.toLowerCase().indexOf(filter.toLowerCase()) > -1 ||
                o.id.toLowerCase().indexOf(filter.toLowerCase()) > -1 ||
                organisationalUnits.filter(x => x.leftEdge > o.leftEdge && x.rightEdge < o.rightEdge && x.depth > o.depth && x.name.toLowerCase().indexOf(filter.toLocaleLowerCase()) > -1).length > 0 ||
                organisationalUnits.filter(x => x.leftEdge > o.leftEdge && x.rightEdge < o.rightEdge && x.depth > o.depth && x.id.toLowerCase().indexOf(filter.toLocaleLowerCase()) > -1).length > 0;
        }));
    }, [filter, organisationalUnits]);

    const handleModalOpen = (org) => {
        setUpdateOrg(org);
        setShowOrganisationModal(true);
    }

    const handleCancel = () => {
        setShowOrganisationModal(false);
        setUpdateOrg(null)
    }

    
    const onSave = (newName, newFieldUserLimit, newOfficeUserLimit) => {

        setIsLoading(true);

        api.updateOrganisationalUnit(updateOrg, newName, parseInt(newFieldUserLimit), parseInt(newOfficeUserLimit), (data) => {
            setIsLoading(false);
            refreshUser();
        },
        () => {
            setIsLoading(false);
        })

        setShowOrganisationModal(false);
    }


    return (
        <Container className='pt-5 pb-lg-4 mt-5 mb-sm-2 report user-activity-report'>
            <Row>
                <Col className='mb-5'>
                    <div className='d-flex justify-content-between my-5'>
                        <h1 className="h2 m-0">Organisations</h1>
                        <FilterBar value={ filter } onChange={ setFilter } className="mb-4" />
                    </div>
                    {
                        isLoading
                            ? <Loading />
                            : <Table responsive hove sm>
                                <thead>
                                    <tr>
                                        <th className="text-left" style={ { paddingLeft: "0" } }>Name</th>
                                        <th className="text-center">Field User Limit</th>
                                        <th className="text-center">Office User Limit</th>
                                        <th className="text-center">&nbsp;</th>
                                    </tr>
                                </thead>
                                <tbody className="organisation-table">
                                    {
                                        !arrayUtilities.isNullOrEmpty(filteredOrganisations) && filteredOrganisations
                                            .map((org, index) =>
                                                <tr className="align-middle" key={ org.id } index={ index }>
                                                    <td key={ `organisation-selector-${ org.id }` } className={ `org-name organisation-level-${ org.depth - filteredOrganisations[0].depth }` }>
                                                        <>
                                                        {
                                                            index > 0 && org.depth > filteredOrganisations[index - 1].depth &&
                                                            <><i className={`fi-corner-down-right indent-icon-${org.depth - filteredOrganisations[0].depth}`}></i></>
                                                        }
                                                        <span className='btn-link'>{ org.name }</span>
                                                        </>
                                                    </td>
                                                    <td className="text-center">{ stringUtilities.formatDecimal(org.fieldUserLimit, 0) }</td>
                                                    <td className="text-center">{ stringUtilities.formatDecimal(org.officeUserLimit, 0) }</td>
                                                    <td className="text-end">
                                                        <Dropdown>
                                                            <Dropdown.Toggle className='bg-none btn-secondary organisation-options-dropdown-toggle'><i className='fi-dots-vertical'></i></Dropdown.Toggle>
                                                            <Dropdown.Menu align='end' className='my-1'>
                                                                {user && user.roleId === 0 && <Dropdown.Item onClick={ () => handleModalOpen(org) }>Edit</Dropdown.Item>}
                                                                <Dropdown.Item onClick={() => navigate(`/organisations/${org.id}/api-keys`)} eventKey='2'>API Keys</Dropdown.Item>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </td>
                                                </tr>)
                                    }
                                </tbody>
                            </Table>
                    }
                </Col>
            </Row>
            { showOrganisationModal &&
                <OrganisationModal
                    handleSave={(newName, newFieldUserLimit, newOfficeUserLimit) => onSave(newName, newFieldUserLimit, newOfficeUserLimit)}
                    org={updateOrg}
                    onCancel={ () => handleCancel() }
                /> }
        </Container>
    )
}