import { useState, useEffect } from 'react';
import { Col, Container, Modal, Row, Button } from 'react-bootstrap';
import { ApiKeyAddEdit } from './common/formsCreateEdit/apiKeyAddEdit';
import useApi from '../utilities/useApi';
import stringUtilities from '../utilities/stringUtilities';
import { useParams, useNavigate } from 'react-router-dom';
import { ApiLicenceAgreement } from './apiLicenceAgreement';
import SimpleBar from "simplebar-react";

export default function AddApiKey(props) {
    const navigate = useNavigate();
    const { api } = useApi();
    const { organisationId } = useParams();
    const [apiKeyName, setApiKeyName] = useState("");
    const [roleId, setRoleId] = useState(null);
    const [expiresAt, setExpiresAt] = useState(null);
    const [acceptedTerms, setAcceptedTerms] = useState(false);
    const [canSave, setCanSave] = useState(false);
    const [showTermsModal, setShowTermsModal] = useState(false);

    useEffect(() => setCanSave(!stringUtilities.isNullOrEmpty(apiKeyName) && roleId && acceptedTerms), [apiKeyName, roleId, acceptedTerms])

    const handleSubmit = () => {
        api.addApiKey(
            organisationId,
            apiKeyName, roleId, expiresAt,
            () => navigate(`/organisations/${ organisationId }/api-keys`),
            error => console.log(error)
        )
    }

    return (
        <Container className='pt-5 pb-lg-4 mt-5 mb-sm-2'>
            <Row>
                <Col className='mb-5'>
                    <div className='d-flex justify-content-between my-5'>
                        <h1 className="h2 m-0">Add API Key</h1>
                    </div>

                    <ApiKeyAddEdit
                        apiKeyName={ apiKeyName }
                        setApiKeyName={ setApiKeyName }
                        roleId={ roleId }
                        setRoleId={ setRoleId }
                        expiresAt={ expiresAt }
                        setExpiresAt={ setExpiresAt }
                        canSave={ canSave }
                        onCancel={ () => navigate(`/organisations/${ organisationId }/api-keys`) }
                        onSave={ () => handleSubmit() }
                        userRoleId={ props.user.roleId }
                        acceptedTerms={ acceptedTerms }
                        setAcceptedTerms={ setAcceptedTerms }
                        handleTermsClick={ () => setShowTermsModal(true) }
                    />
                </Col>
            </Row>
            <Modal show={ showTermsModal } onHide={ () => setShowTermsModal(false) } centered size='lg'>
                <Modal.Body>
                    <SimpleBar autoHide={ false } className='simplebar-no-autohide pe-3' style={ { maxHeight: 'calc(100vh - 380px)' } }>
                        <ApiLicenceAgreement />
                    </SimpleBar>
                </Modal.Body>
                <Modal.Footer>
                    <Button size="sm" variant='secondary' onClick={ () => setShowTermsModal(false) }>Close</Button>
                </Modal.Footer>
            </Modal>
        </Container>
    )
}