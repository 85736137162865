import { Button, Form } from "react-bootstrap";
import Enums from "../../../utilities/enums";
import DatePicker from 'react-datepicker';

export const ApiKeyAddEdit = ({ apiKeyName, setApiKeyName, roleId, setRoleId, expiresAt, setExpiresAt, status, setStatus, acceptedTerms, setAcceptedTerms, canSave, onCancel, onSave, userRoleId, handleTermsClick }) => {
    return (
        <div>
            <Form.Group className='mb-3 row'>
                <Form.Label className='form-label fs-sm col-md-3' htmlFor="api-key-name">
                    Name
                </Form.Label>
                <div className="col-md-9 d-flex">
                    <Form.Control size='sm' value={ apiKeyName } type="text" onChange={ e => setApiKeyName(e.target.value) } />
                </div>
            </Form.Group>
            <Form.Group className='mb-3 row'>
                <Form.Label className='form-label fs-sm col-md-3'>
                    Role
                </Form.Label>
                <div className="col-md-9">
                    {
                        Object.entries(Enums.UserRole).map(([key, value]) => {
                            if (key > 0 && key >= userRoleId) {
                                return <Form.Check
                                    key={ key }
                                    type='radio'
                                    id={ `roleId-${ key }` }
                                    name='radio'
                                    value={ key }
                                    checked={ roleId === Number(key) }
                                    label={ value }
                                    onChange={ e => setRoleId(Number(e.target.value)) }
                                />
                            }
                        })
                    }
                </div>
            </Form.Group>
            <Form.Group className="mb-3 row">
                <Form.Label className='form-label fs-sm col-md-3'>
                    Expiry Date
                </Form.Label>
                <div className="col-md-9 d-flex gap-2 align-items-center">
                    <div>
                        <Form.Control
                            as={ DatePicker }
                            selected={ expiresAt ? new Date(expiresAt) : null }
                            onChange={ date => setExpiresAt(date) }
                            dateFormat="dd/MM/yyyy"
                            className='rounded-start form-control-sm'
                        />
                    </div>
                    <i role="button" onClick={ () => setExpiresAt(null) } className='fi-x'></i>
                </div>
            </Form.Group>
            {
                setStatus &&
                <Form.Group className='mb-3 row align-items-center'>
                    <Form.Label className='form-label fs-sm col-form-label col-md-3' htmlFor="api-key-status">
                        Enabled
                    </Form.Label>
                    <div className="col-md-9 d-flex align-items-center">
                        <Form.Check type='switch' id="api-key-status" checked={ status } onChange={ e => setStatus(e.target.checked) } />
                    </div>
                </Form.Group>
            }
            <Form.Group className='mt-4 row justify-content-end'>
                <div className="col-md-9 d-flex flex-column gap-3">
                    {
                        setAcceptedTerms &&
                        <div className="d-flex">
                            <input id="terms-accept-checkbox" onChange={ () => setAcceptedTerms(!acceptedTerms) } className="form-check-input cursor-pointer me-2" type="checkbox"/>
                            <label htmlFor="terms-accept-checkbox" className="m-0 cursor-pointer">I have read and accept the terms in the</label>&nbsp;<span role="button" className="text-decoration-underline" onClick={ () => handleTermsClick() }> TerraFlow Geomatics API Licence Agreement</span>
                        </div>
                    }
                    <div className="d-flex mt-2">
                        <Button size="sm" variant='secondary' onClick={ onCancel } className="me-2">Cancel</Button>
                        <Button size="sm" variant='primary' onClick={ onSave } disabled={ !canSave }>Save</Button>
                    </div>
                </div>
            </Form.Group>
        </div>

    )
}