import { Container, Row, Col, Button, Form } from "react-bootstrap";

export const SurfaceAddEdit = ({ title, name, setName, filename, handleChange, handleCancel, handleSubmit, canSave, inputRef, errorMessage }) => {
    return (
        <Container className='pt-5 pb-lg-4 mt-5 mb-sm-2'>
            <Row>
                <Col className='mb-5'>
                    <div className='d-flex justify-content-between my-5'>
                        <h1 className="h2 m-0">{ title }</h1>
                    </div>
                    <div>
                        <Form.Group className='mb-3 row'>
                            <Form.Label className='form-label fs-sm col-form-label col-md-3' htmlFor="project-name">
                                Name
                            </Form.Label>
                            <div className="col-md-9 d-flex align-items-center">
                                <Form.Control size='sm' value={ name } type="text" onChange={ (e) => setName(e.target.value) } />
                            </div>
                        </Form.Group>
                        <Form.Group className='mb-3 row'>
                            <Form.Label className='form-label fs-sm col-form-label col-md-3' htmlFor="surface-fileUpload">
                                CSV
                            </Form.Label>
                            <div className="col-md-9 d-flex align-items-start flex-column file-input">
                                <input className="file-upload-button" ref={ inputRef } onChange={ e => handleChange(e) } type="file" id="surface-fileUpload" name="surface-file" accept=".csv" />
                                <div className="d-flex align-items-center">
                                    <label for="surface-fileUpload"><i className='fi-cloud-upload me-2'></i>Select file</label>
                                    <p className="m-0">{ filename }</p>
                                    {errorMessage && <p className='m-0 text-danger'>{errorMessage}</p>}
                                </div>
                                <p className="m-0 mt-1" style={ { fontSize: "12px" } }>Maximum file size is 10MB. CSV row format should be: x1, y1, z1, x2, y2, z2, x3, y3, z3</p>
                            </div>
                        </Form.Group>
                    </div>
                    <Form.Group className='mt-4 row justify-content-end'>
                        <div className="col-md-9">
                            <Button onClick={ handleCancel } variant='outline-primary' size="sm">
                                Cancel
                            </Button>
                            <Button disabled={ !canSave } onClick={ handleSubmit } size='sm' className='ms-2'>
                                Submit
                            </Button>
                        </div>
                    </Form.Group>
                </Col>
            </Row>
        </Container>
    )
}