var moment = require('moment'); // require

export default {

    emptyGuid: "00000000-0000-0000-0000-000000000000",

    isNullOrEmpty(str) {
        return !str || str === undefined || str === null || str === "" || Array.isArray(str) || str.trim() === "";
    },

    anyAreNullOrEmpty(strings) {

        let result = false;
        strings.forEach(str => {
            if (this.isNullOrEmpty(str)) {
                result = true;
            }
        });
        return result;
    },

    isNullOrWhiteSpace(str) {
        return !str || str === null || str.trim().length === 0;
    },

    toInitials(str) {

        let nameParts = str.split(" ");
        if (nameParts && nameParts !== null && nameParts.length > 0) {
            let result = "";

            for (let i = 0; i < nameParts.length; i++)
            {
                if (!this.isNullOrWhiteSpace(nameParts[i])) {
                    result += nameParts[i].substr(0, 1).toUpperCase();
                }
            }
            
            return result.substr(0, Math.min(2, result.length));
        }

        return "";
    },

    camelCaseToWords(str) {
        let result = str.replace(/((?<=[a-z])[A-Z]|[A-Z](?=[a-z]))/g, " $1");
        result = result.charAt(0).toUpperCase() + result.slice(1);
        
        result = result.replace(/gps/gi, "GPS");
        result = result.replace(/pdop/gi, "PDOP");
        result = result.replace(/hdop/gi, "HDOP");
        result = result.replace(/vdop/gi, "VDOP");
        result = result.replace(/\bId\b/g, "ID");

        return result;
    },

    formatDate(str) {
        
        let datetime = moment(str,moment.ISO_8601, true);
        if (datetime.isValid()) {
            return datetime.format("DD MMM YYYY HH:mm:ss"); 
        }

        return str;
    },

    photoFormat(contentType) {
        if (!this.isNullOrWhiteSpace(contentType)) {
            switch (contentType.toLowerCase()) {
                case "image/bmp":
                    return "bmp";
                case "image/gif":
                    return "gif";
                case "image/jpeg":
                    return "jpg";
                case "image/png":
                    return "png";
            }
        }

        return null;
    },

    toFriendlyString(str) {
        return this.isNullOrEmpty(str) ? '' : str.replace(/[^a-z0-9_]+/gi, '-').replace(/^-|-$/g, '').toLowerCase();
    },

    formatDecimal(number, decimalPlaces = 2) {

        if (!isNaN(number)) {

            var isNegative = number < 0;
            var unformatted = "" + Math.abs(number).toFixed(decimalPlaces);
            var commaFormatted = "";

            var indexIterator = unformatted.indexOf(".");
            if (indexIterator < 0) {
                indexIterator = unformatted.length;
            }

            for (var i = indexIterator - 1; i >= 0; i--) {
                commaFormatted = unformatted.substr(i, 1) + commaFormatted;
                if (i > 0 && commaFormatted.replace(/,/g, "").length % 3 === 0) {
                    commaFormatted = "," + commaFormatted;
                }
            }

            if (isNegative) {
                commaFormatted = "-" + commaFormatted;
            } 

            return commaFormatted + (unformatted.indexOf(".") > -1 ? unformatted.substring(unformatted.indexOf(".")) : "");
        } else {
            var result = 0;
            return result.toFixed(decimalPlaces);
        }
    },

    ensureStringHasPrefix(str, prefix) {
        if (!str.startsWith(prefix)) {
            return `${prefix}${str}`;
        } 
        
        return str;
    },

    checkColorHash(color) {
        if (this.isNullOrEmpty(color)) {
            return null
        } return color.startsWith("#") ? color : `#${ color }`
    },

    validateJsonWithDebouncer (jsonString, ref, callback, debouncerDuration = 500) {
        if (ref.current) {
            clearTimeout(ref.current);
            ref.current = null;
        }
    
        ref.current = setTimeout(() => {
            try {
                JSON.parse(jsonString);
                callback(true)
            } catch (error) {
                callback(false);
            }
        }, debouncerDuration);
    }
};
