import './scss/theme/theme.scss'
import './scss/app.scss'

import StickyNavbar from './components/theme/StickyNavbar'
import Navbar from 'react-bootstrap/Navbar'
import Container from 'react-bootstrap/Container'
import Nav from 'react-bootstrap/Nav'
import Dropdown from 'react-bootstrap/Dropdown'

import Logo from './images/terraflow-logo.png';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Projects from './components/projects'
import { useEffect, useState } from 'react'
import useApi from './utilities/useApi';
import Gravatar from './components/common/gravatar'
import arrayUtilities from './utilities/arrayUtilities'
import Jobs from './components/jobs'
import Map from './components/map'
import ScrollToTop from './components/common/scrollToTop'
import Loading from './components/common/loading'
import stringUtilities from './utilities/stringUtilities'
import postal from 'postal';
import ErrorModal from './components/common/errorModal';
import Stamps from './components/settings/stamps'
import UserActivity from './components/reports/userActivity'
import Users from './components/users'
import DeviceManagement from './components/settings/deviceManagement'
import Organisations from './components/organisations';
import TermsAndConditions from './components/termsandconditions'
import AddProject from './components/addProject'
import EditProject from './components/editProject'
import Workflows from './components/workflows'
import AddWorkflow from './components/addWorkflow'
import EditWorkflow from './components/editWorkflow'
import KMLFiles from './components/kmlFiles'
import AddKmlFile from './components/addKml'
import Surfaces from './components/surfaces'
import AddSurface from './components/addSurface'
import EditSurface from './components/editSurface'
import EditWorkflowTemplate from './components/editWorkflowTemplate'
import ApiKeys from './components/apiKeys'
import AddApiKey from './components/addApiKey'
import EditApiKey from './components/editApiKey'

function App() {
  const { api } = useApi();

  const [user, setUser] = useState(null);
  const [organisationalUnits, setOrganisationalUnits] = useState(null);
  const [organisationId, setOrganisationId] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  if (!stringUtilities.isNullOrEmpty(window.top.location.hash) && window.top.location.hash.startsWith("#state=")) {

    let token = window.top.location.hash.substring(window.top.location.hash.indexOf("=") + 1);
    api.setToken(token);

    let redirect = window.top.location.href.substring(0, window.top.location.href.indexOf("#"));
    window.top.location = redirect;

  }

  const refreshUser = function () {
    api.getUser((data) => {
      setUser(data);
      setIsLoading(false);
      setOrganisationId(data.organisationalUnitId);

      api.getOrganisationalUnits((data) => {
        setOrganisationalUnits(data);
      })
    }, () => {
      setUser(null);
      setIsLoading(false);
    });
  }

  const signout = function () {
    api.signout();
  }

  useEffect(() => {

    const subscriptions = [

      postal.subscribe({
        channel: "error",
        topic: "thrown",
        callback: function (data) {
          setError(data.error);
        }
      })
    ];

    const timer = setTimeout(() => {
      refreshUser();
    }, 500);
    return () => {
      clearTimeout(timer);
      subscriptions.forEach(subscription => {
        subscription.unsubscribe();
      })
    }
  }, []);

  return (
    <main className='page-wrapper'>
      {isLoading && <div className='full-screen-wrapper'>
        <Loading blur />
      </div>}
      {!isLoading && <>
        {user && user !== null && <>
          <Navbar as={StickyNavbar} expand='md' className='fixed-top'>
            <Container fluid>
              <Navbar.Brand href='/' className='me-2 me-xl-4'>
                <img src={Logo} style={{ maxWidth: "200px" }} />
              </Navbar.Brand>
              <Navbar.Toggle aria-controls='navbarNav' className='ms-auto' />

              <Dropdown className='d-none d-lg-block order-lg-3 my-n2 me-3'>
                <Dropdown.Toggle as={Nav.Link} className='nav-lik dropdown-toggle-flush d-flex py-1 px-0' style={{ width: '40px' }}>
                  <Gravatar user={user} />
                </Dropdown.Toggle>
                <Dropdown.Menu renderOnMount align='end'>
                  <div className='d-flex align-items-start border-bottom px-3 py-1 mb-2' style={{ width: '16rem' }}>
                    <Gravatar user={user} width="48" height="48" />
                    <div className='ps-2'>
                      <h6 className='fs-base mb-0'>{user.name}</h6>
                      <div className='fs-xs py-2'>
                        {user.emailAddress}
                      </div>
                    </div>
                  </div>
                  <Dropdown.Item className='dropdown-item' onClick={signout}>Sign Out</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>

              <Navbar.Collapse id='navbarNav' className='order-md-2'>
                <Nav navbarScroll style={{ maxHeight: '35rem' }}>
                  <Nav.Item>
                    <Nav.Link href='/'><i className='fi-layers me-2' /> Projects
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link href='/organisations'><i class="fi-building me-2"></i> Organisations
                    </Nav.Link>
                  </Nav.Item>
                  {(user.roleId === 0 || user.roleId === 1) && <>
                    <Nav.Item>
                      <Nav.Link href='/users'><i className='fi-users me-2' /> Users
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item as={Dropdown}>
                      <Dropdown.Toggle as={Nav.Link}><i className='fi-file me-2' />Reports</Dropdown.Toggle>
                      <Dropdown.Menu renderOnMount>
                        <Dropdown.Item href='/reports/user-activity'>User Activity</Dropdown.Item>
                      </Dropdown.Menu>
                    </Nav.Item>
                  </>}
                  {(user.roleId === 0 || user.roleId === 1) &&
                    <Nav.Item as={Dropdown}>
                      <Dropdown.Toggle as={Nav.Link}><i className='fi-settings me-2' />Settings</Dropdown.Toggle>
                      <Dropdown.Menu renderOnMount>
                        <Dropdown.Item href='/settings/device-management'>Device Management</Dropdown.Item>
                        <Dropdown.Item href='/settings/stamps'>Stamps</Dropdown.Item>
                      </Dropdown.Menu>
                    </Nav.Item>
                  }
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>

          <div>
            {user && user !== null && !arrayUtilities.isNullOrEmpty(organisationalUnits) && organisationId && 
              <>
                { user.acceptedTerms ?
                  <Router>
                  <ScrollToTop />              
                    <Routes>
                      <Route path=":organisationId?/projects?" element={ <Projects changeOrgId={id => setOrganisationId(id)} organisationId={organisationId} organisationalUnits={organisationalUnits} /> } />
                      <Route path=":organisationId?/projects?/add" element={ <AddProject organisationalUnits={organisationalUnits} /> } />
                      <Route path=":organisationId?/projects?/:projectId" element={ <EditProject organisationalUnits={organisationalUnits} /> } />
                      <Route path=":organisationId?/projects/:projectId/jobs" element={ <Jobs organisationId={organisationId} user={user} /> } />
                      <Route path=":organisationId?/projects/:projectId/workflows" element={ <Workflows organisationId={organisationId} user={user} /> } />
                      <Route path=":organisationId?/projects/:projectId/workflows/add" element={ <AddWorkflow organisationId={organisationId} user={user} /> } />
                      <Route path=":organisationId?/projects/:projectId/workflows/:workflowId/edit" element={ <EditWorkflow organisationId={organisationId} user={user} /> } />
                      <Route path=":organisationId?/projects/:projectId/workflow-templates/:workflowTemplateId/edit" element={ <EditWorkflowTemplate organisationId={organisationId} user={user} /> } />
                      <Route path=":organisationId?/projects/:projectId/kml-files" element={ <KMLFiles organisationId={organisationId} user={user} /> } />
                      <Route path=":organisationId?/projects/:projectId/kml-files/add" element={ <AddKmlFile organisationId={organisationId} user={user} /> } />
                      <Route path=":organisationId?/projects/:projectId/surfaces" element={ <Surfaces organisationId={organisationId} user={user} /> } />
                      <Route path=":organisationId?/projects/:projectId/surfaces/add" element={ <AddSurface organisationId={organisationId} user={user} /> } />
                      <Route path=":organisationId?/projects/:projectId/surfaces/:surfaceId/edit" element={ <EditSurface organisationId={organisationId} user={user} /> } />
                      <Route path=":organisationId?/projects/:projectId/map" element={ <Map organisationId={organisationId} user={user} /> } />
                      <Route path=":organisationId?/reports/user-activity" element={<UserActivity organisationId={organisationId} organisationalUnits={organisationalUnits} /> } />
                      <Route path=":organisationId?/settings/device-management" element={ <DeviceManagement organisationId={organisationId} organisationalUnits={organisationalUnits} /> } />
                      <Route path=":organisationId?/settings/stamps" element={<Stamps organisationId={organisationId} organisationalUnits={organisationalUnits} /> } />
                      <Route path=":organisationId?/users" element={<Users organisationId={organisationId} organisationalUnits={organisationalUnits} /> } />
                      <Route path=":organisationId?/organisations" element={<Organisations organisationId={organisationId} organisationalUnits={organisationalUnits} /> } />
                      <Route path="organisations/:organisationId/api-keys" element={<ApiKeys organisationId={organisationId} organisationalUnits={organisationalUnits} user={user} /> } />
                      <Route path="organisations/:organisationId/api-keys/add" element={<AddApiKey organisationId={organisationId} organisationalUnits={organisationalUnits} user={user} /> } />
                      <Route path="organisations/:organisationId/api-keys/:apiKeyId/edit" element={<EditApiKey organisationId={organisationId} organisationalUnits={organisationalUnits} user={user} /> } />
                    </Routes>              
                </Router>
                : <TermsAndConditions updateUser={setUser} />
                }
              </>
            }
          </div>

          {error && error !== null &&
            <ErrorModal error={error} onDismiss={() => setError(null)} />
          }


          <footer className='footer bg-secondary pt-5'>
            <Container className='pt-lg-4 pb-4'>
              <div className='text-center fs-sm pt-4 mt-3 pb-2'>
                &copy; {(new Date()).getFullYear()} TerraFlow Geomatics Inc
              </div>
            </Container>
          </footer>
        </>
        }
      </>}
    </main>
  );
}

export default App;
